import React from "react";
export default function InlinedElement(props) {
  return (
    <div className="InFlx flxDrc FlHg BtNseti AlgnItm" style={props.style}>
      {props.first && <div className="RlPs TxtCn StMrtg">{props.first}</div>}
      {props.secnd && (
        <div className={"RlPs TxtCn " + (props.leftElm ? "StMrtg" : "")}>
          {props.secnd}
        </div>
      )}
      {props.leftElm && <div className="StAutMr InFlx">{props.leftElm}</div>}
    </div>
  );
}
