import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import {
  Translate,
  FormatDate,
} from "../../../utils/lang/translate";
import {
  dashboardActions,
  paymentActions,
  ordersActions,
  productActions,
  alertActions
} from "../../../actions";
import ActionsList from "../_Common/ActionsList";
import { initGAPg, Event } from "../_Common";
import TableLoader from "../_Common/TableLoader";
import { ExcelSvg, FileDownSvg, MoneySvg } from "../_Common/IconSvg";
import FileExporter from "../../../utils/exporter";


class BackupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.results
          : [],
      gettingGlobalStatistics: false, // 2 fetch are used with same action, one for period related statistics, the other with no date
      globalStats: null,
    };
    // this.gettingGlobalStatistics=false; // 2 fetch are used with same action, one for period related statistics, the other with no date
    this.crntfilter = "";
    this.dateRef = React.createRef();
    this.actionDec = false;

  }

  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "backups");
    initGAPg();

    // Fetch financial stats
    this.props.ViewPayment();
    this.props.GetOrders("");
    this.props.ViewStockProduct();
  }
  componentWillReceiveProps(nextProps) {
    
   
    const { OrderGeting, OrdersList } = nextProps;
    if (
      this.props.OrderGeting === "1" &&
      OrderGeting === "2" &&
      OrdersList &&
      OrdersList.ressuc &&
      OrdersList.ressuc.list
    ) {
      this.setState({
        orders: OrdersList.ressuc.list.results,
      });
    }
  }
  CallExportOrder(e,fileEx){
    e.preventDefault()
    if(this.props.Exporting!=="0"){
        this.props.SendAlert("31",Translate('alert','exportreqstsent'),"a04")
        this.props.ExportOrder(this.props.location.search.replaceAll('.',',').replace('prduord','products'),fileEx)
    }
  }

  CallExportBills(e){
    e.preventDefault()
    if(this.props.Exporting!=="0"){
        this.props.SendAlert("31",Translate('alert','exportreqstsent'),"a04")
        this.props.ExportFactures("xlsx",(data)=>this.ExportWorkFunc(data))
    }
  }
  ExportWorkFunc(data){
    const {ViewPaydata}=this.props
    let bindThis=this;
    var dict=[
      {key:"facture_delivered_product",title: Translate("payement","facturedeliveredproduct")},
      {key:"facture_echange",title: Translate("payement","factureechange")},
      {key:"facture_extra_service",title: Translate("payement","factureextraservice")},
    ];
    let Header=[Translate("payement","description"),Translate("payement","price")];
    let billsHeader=["#",Translate("profile","date"),Translate("payement","invoiceelements"),Translate("payement","price")];

    let sheets=[];
      // Billing History sheet
    sheets.push({
      sheetTitle: Translate("titles","billinghist"),
      Header:billsHeader,
      Rows: ViewPaydata.payment.map((elem,key)=>{
        return {content: [key+1000,FormatDate(elem.payment_date),elem.nb_orders,elem.product_price.toFixed(2)]}
      })
    })
    dict.forEach(facture=>{
      let sheetTitle=facture.title, factData=data[facture.key];
      let Rows=factData.map(row=>({content: [row.description,row.amount]}))
      sheets.push({Header,Rows,sheetTitle})
    })
    new FileExporter(undefined).exportExcel(sheets);
    bindThis.props.SendAlert("41", Translate("alert", "orderexscc"), "a05");

  }




  render() {
    const {
      OrderGeting,
      ViewPay,
    } = this.props;

   
    // Actions items
    let actions = [];
    if (OrderGeting === "2" && ViewPay==="1") {
      actions.push({
        title: Translate('orders','exportordcsv'),
        icon: FileDownSvg,
        onClick: (e)=>this.CallExportOrder(e,'csv')
      });
      actions.push({
        title: Translate('orders','exportordxsl2007'),
        icon: ExcelSvg,
        onClick: (e)=>this.CallExportOrder(e,'xlsx')
      });
      actions.push({
        title: Translate('orders','exportordxsl97'),
        icon: ExcelSvg,
        onClick: (e)=>this.CallExportOrder(e,'xls')
      });
    }
    
    actions.push({
      title: Translate('payement','exportpayementhist'),
      icon: MoneySvg,
      onClick: (e)=>this.CallExportBills(e)
    });
    
   
    return (
      <ContainerPage
        page_title={Translate("titles", "backups")}
        // data_top={FIlterMon}
      >
        <div className="flex-grid FlWd StDrc spctwB">
          <div
            className="col"
            style={{ width: "75% !important", flexGrow: 0.99 }}
          >
            {OrderGeting==="1"  && <TableLoader/>}
            
            {OrderGeting==="2"  &&
            <div className="FlWd " style={{padding: "5px"}}>
              <ActionsList items={actions} />
            </div>}
           

            
            
          </div>
          
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { dtStoreinfo, dataProfile } = state.user;
  const { OrderGeting, OrdersList } = state.orders;
  const { GetinunfOrd, dataInfOrd } = state.unfOrders;
  const { StockLoadded, DataStock } = state.Stock;
  const { data } = state.product;
  const {
    GetingStat,
    Statdata,
    GetingGlobalStat,
    GlobalStats,
    GetingTopProd,
    TopProdData,
  } = state.dashboard;
  const {GetingFacture,Facturedata}=state.facturepayment

  const { ViewPay, ViewPaydata } = state.Viewpayment;
  return {
    dtStoreinfo,
    dataProfile,
    OrderGeting,
    OrdersList,
    GetinunfOrd,
    dataInfOrd,
    StockLoadded,
    DataStock,
    data,
    GetingStat,
    Statdata,
    GetingGlobalStat,
    GlobalStats,
    GetingTopProd,
    TopProdData,
    ViewPay,
    ViewPaydata,
    GetingFacture,Facturedata
  };
}

const actionCreators = {
  GetStatus: dashboardActions.GetStatus,
  GetOrders: ordersActions.GetOrders,
  ViewStockProduct: productActions.ViewStockProduct,
  GetFacture:paymentActions.GetFacture,
  ExportFactures:paymentActions.ExportFactures,
  ViewPayment: paymentActions.ViewPayment,
  ExportOrder:ordersActions.ExportOrder,
  SendAlert:alertActions.SendAlert,

};

const connectedBackupPage = connect(mapState, actionCreators)(BackupPage);
export { connectedBackupPage as BackupPage };
