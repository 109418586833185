import React from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils/lang/translate';
import { NEW_FEATURES } from '../../../utils/new-features'
import ContainerPage from '../_Common/ContainerPage'

export function UpdatesPage(props) {
  const {storeInfos}=props
    const featuresToShow=NEW_FEATURES.filter(feat=>{
      return feat.stores==="all" || (feat.stores==="premium" && storeInfos.stock_managed) || (feat.stores==="starter" && storeInfos.stock_managed===false)
    }).reverse()
    return (
        <ContainerPage
        page_title={"System & Features Updates"}
        withOvrl
        >
            {featuresToShow.map((feature,index)=>{
                const { title, desc, media, link } = feature;
                let body;
                switch (media) {
                  case "img":
                    body = <img src={link} alt="" style={{maxWidth: "90%"}}/>;
                    break;
                  case "video":
                    body = (
                      <iframe
                        style={{ width: "100%", minHeight: "50vh", border: "none" }}
                        src={link}
                        title={`${Translate("titles", "newfeaturetitle")} ${index}`}
                      />
                    );
                    break;
      
                  default:
                    body = <div></div>;
                    break;
                }
                return (
                  <div key={index} className="SmMarS FlWd">
                    <h5 className='FlWd Inlflx flxDrc'>{title}</h5>
                    <p className='FlWd Inlflx flxDrc'>{desc}</p>
                    <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
                        {body}
                    </div>
                    
                  </div>
                );
            })}
            
            
        </ContainerPage>
    )
}
UpdatesPage.propTypes = {
}

const mapStateToProps = (state) => ({
  storeInfos: state.user.dtStoreinfo
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatesPage)