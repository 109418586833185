import React from "react"
import ContentLoader from "react-content-loader"
const SideBarLoader = (props) => (
    <ContentLoader 
    speed={2}
    viewBox="0 0 210 500"
    backgroundColor="var(--bdgclInp)"
    foregroundColor="var(--defcl)"
    {...props}
  > 
    <rect x="5%" y="20" rx="3" ry="3" style={{width:"90%"}} height="35" />
    <rect x="5%" y="80" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="80" rx="3" ry="3" style={{width:"67%"}} height="37" />
    <rect x="5%" y="130" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="130" rx="3" ry="3" style={{width:"67%"}} height="37" />
    <rect x="5%" y="180" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="180" rx="3" ry="3" style={{width:"67%"}} height="37" />
    <rect x="5%" y="230" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="230" rx="3" ry="3" style={{width:"67%"}} height="37" />

    <rect x="5%" y="290" rx="0" ry="0" style={{width:"90%"}} height="2" />

    <rect x="5%" y="315" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="315" rx="3" ry="3" style={{width:"67%"}} height="37" />
    <rect x="5%" y="365" rx="3" ry="3" style={{width:"18%"}} height="37" />
    <rect x="28%" y="365" rx="3" ry="3" style={{width:"67%"}} height="37" />
  </ContentLoader>
  )
  
  export default SideBarLoader

 