import React,{Component} from 'react'
import {TeamIndex} from './TeamIndex'
import { Switch, Route ,Redirect} from 'react-router-dom'
import {AddTeam} from './AddTeam'
import {Translate} from '../../../../utils/lang/translate'
import {connect} from 'react-redux'
import {ViewTeam} from './ViewTeam'
import {FeatureLoked} from '../../_Common/FeatureLoked'
class TeamPage extends Component{
    render(){
        if(this.props.dataProfile.is_store){
            return(
                <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
                    <Switch>
                        <Route path="/settings/team/home" component={TeamIndex}/>
                        <Route path="/settings/team/add/:idteam" component={AddTeam}/>
                        <Route path="/settings/team/view/:idteam" component={ViewTeam}/>
                        <Redirect from="*" to="/settings/team/home"/>
                    </Switch>
                </div>
            )
        }else{
            return (
                <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
                    <div className="stbd2">
                        <h3 className="DlMg">{Translate('settings','storeteamor')}</h3>
                    </div>
                    <FeatureLoked/>
                </div>
            )  
        }
    } 
}
function mapState(state) {
    const {dataProfile}=state.user
    return { dataProfile };
}
const connectedTeamPage = connect(mapState)(TeamPage);
export { connectedTeamPage as TeamPage };