import React from "react";
import { VisitorsSourceInterface } from "../../../types";
import { Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import AlgerianMap from "./AlgerianMap";
import VisitorsSourceChartBars from "./VisitorsSourceChartBars";

interface Props {
  visitorsSources: VisitorsSourceInterface[];
}

const VisitorsSource: React.FC<Props> = ({ visitorsSources }) => {
  return (
    <div className="VisitorsSource">
      <ContainerPage
        page_title={Translate("titles", "visitorsSource")}
        small_title
        lineHeader
        columnTitles
        subContainer
      >
        <AlgerianMap wilayas={visitorsSources.map((elem) => elem.wilaya)} />
        <VisitorsSourceChartBars visitorsSources={visitorsSources} />
      </ContainerPage>
    </div>
  );
};

export default VisitorsSource;
