import {dashboardConstants} from '../constants'
import { RequestService,FunctionService } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')
const store_id=Cookies.get('c_store')
export const dashboardActions = {
    GetStatus,
    GetTodayStats,
    GetGlobalStats,
    GetTopProduct
};
function GetStatus(filter,nodate){
    return dispatch => {
        if(nodate){
            dispatch(FunctionService.request(dashboardConstants.GET_STATIC_STATUS_STORE_REQUEST))

        }
        else{
            dispatch(FunctionService.request(dashboardConstants.GET_STATUS_STORE_REQUEST))

        }
        RequestService.GetRequest("stores/dashboard/web/store/"+filter,{"Authorization":"Token "+token})
        .then(res=>{
            if(nodate)
                dispatch(FunctionService.succes(res.data,dashboardConstants.GET_STATIC_STATUS_STORE_SUCCESS))

            else
                dispatch(FunctionService.succes(res.data,dashboardConstants.GET_STATUS_STORE_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,dashboardConstants.GET_STATUS_STORE_FAILURE))
        })
    }
}
function GetTodayStats(){
    return dispatch => {
       
        dispatch(FunctionService.request(dashboardConstants.GET_TODAY_STATUS_STORE_REQUEST))

    
        RequestService.GetRequest("stores/stat_store/?period=0",{"Authorization":"Token "+token})
        .then(res=>{
                dispatch(FunctionService.succes(res.data,dashboardConstants.GET_TODAY_STATUS_STORE_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,dashboardConstants.GET_TODAY_STATUS_STORE_FAILURE))
        })
    }
}
function GetGlobalStats(filter){
    return dispatch => {
       
        dispatch(FunctionService.request(dashboardConstants.GET_STATIC_STATUS_STORE_REQUEST))

    
        RequestService.GetRequest("stores/stat_status/"+filter,{"Authorization":"Token "+token})
        .then(res=>{
                dispatch(FunctionService.succes(res.data,dashboardConstants.GET_STATIC_STATUS_STORE_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,dashboardConstants.GET_STATUS_STORE_FAILURE))
        })
    }
}

function GetTopProduct(filter){
    return dispatch => {
        dispatch(FunctionService.request(dashboardConstants.GET_TOP_PRODUCT_REQUEST))
        RequestService.GetRequest("stores/top_products/web/store/"+filter,{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes(res.data,dashboardConstants.GET_TOP_PRODUCT_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,dashboardConstants.GET_TOP_PRODUCT_FAILURE))
        })
    }
}