import React from 'react'
import {Translate} from '../../../../utils/lang/translate'
import { Link } from 'react-router-dom'
export default function TabTeam(props){
    return(
            <div className="StWdpwrp BrdBl StBrdRd SthgMaxC">
                <Link to={props.path}>
                    <div className="StMrgAl">
                        <div>
                            <span style={{textTransform:"capitalize"}}>{props.name}</span>
                        </div>
                        <div className="StSmlS StOpcVal">
                            <span className="DsBlc">{Translate('settings','permmision')}</span>
                            <span className="DsBlc">
                                {Array.isArray(props.permissions) && props.permissions.map(codeperm=>{
                                    return Translate('settings',codeperm.codename)+" , "
                                })}
                                </span>
                        </div>
                        <div className="StSmlS StOpcVal Mrtpvl">
                            {props.lstmem.map((elem,key)=>{
                                return <span key={key} className="Inlflx IcnSiz2 StmrTpvl">
                                            <img alt="team avatar"className="IcnSiz2" src={elem ? elem :"/assets/img/default_user.png"}/>
                                        </span>
                            })}
                        </div>
                    </div> 
                </Link>
            </div>
    )
}