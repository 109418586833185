import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import UpdatesPage from './UpdatesPage'
import SideMenu from '../_Common/SideMenu'
import { RUBRIC_MENU_ITEMS } from '../../constants'
import ContainerPage from '../_Common/ContainerPage'

function MainPage(props) {
    return (
        <ContainerPage>
            <div className="InFlx flxDrc responsFlx FlWd">
                <SideMenu
                items={RUBRIC_MENU_ITEMS.slice(0,1)} 
                pathname={window.location.pathname}/>
                <div className="StVl2dt">
                    <Switch>
                        <Route path="/rubric/updateslog" component={UpdatesPage}/>
                        {/* <Route path="/rubric/howto" component={UpdatesPage} /> */}
                    
                        <Redirect from="*" to="/rubric/updateslog"/>
                    </Switch>
                </div>
            </div>

        </ContainerPage>
    )
}

MainPage.propTypes = {

}

export default MainPage

