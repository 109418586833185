import React,{Component} from 'react'
import {LoginPage} from './LoginPage'
import {RegisterPage} from './RegisterPage'
import {ResetPasswordPage} from './ResetPasswordPage'
import {AddStore,JoinStore} from './ActionStorePage'
import {SuccessPage} from './SuccessPage'
import {
    Route,
    Switch,
    Redirect
  } from 'react-router-dom';
import Helmet from 'react-helmet';
import { CompleteRegister } from './RegisterPage/CompleteRegister'
export default class IndexAuth extends Component{
    render(){
        return(
            <div>
                <Helmet>
                <style>{`:root{
                --bdgclInp:#F1F4F9;
                --fntClr:#202224;
                --gcl:#edeef0;
                --defcl:#ffffff;
                --clbxshd:#0000000f;
                --clrbrd:#D8D8D8;
                --bdgclInpT:#ffffff;
                --bdgopct:#0000001f;
                --checbxbr:#bfbfbf;
                --chekclr:#61616114;
            }`}</style>
                </Helmet>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/register/continue" component={CompleteRegister}/>
                    <Route path="/register" component={RegisterPage}/>
                    <Route path="/store/add" component={AddStore}/>
                    <Route path="/store/join" component={JoinStore}/>
                    <Route path="/succes/register" component={SuccessPage}/>
                    <Route path="/password/reset" component={ResetPasswordPage}/>
                    <Redirect from="*" to="/login"/>
                </Switch>
            </div>
        )
    }
}