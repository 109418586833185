import React,{Component} from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../utils/lang/translate'
import {ImgCropper} from '../../../_Common/ImgCropper'
import GroupeForm from '../../../_Common/GroupeForm'
import {userActions,paymentActions} from '../../../../actions'
import Button from '../../../_Common/Button'
import InlinedElement from '../../_Common/InlinedElement'
import LoaderStore from './LoaderStore'
import Loader from '../../../_Common/Loader'
import {Link, Redirect} from 'react-router-dom'
import {initGAPg,Event } from '../../_Common'
class StoreInfo extends Component{
    constructor(props){
        super(props)
        const {dtStoreinfo}=this.props
        this.state={
            store_name:dtStoreinfo ?dtStoreinfo.name:'',
            store_location:dtStoreinfo ?dtStoreinfo.address:'',
            stock_managed:dtStoreinfo?dtStoreinfo.stock_managed:'',
            profilepic:dtStoreinfo?dtStoreinfo.picture:''
        }
        this.handleChange=this.handleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const {gtStoreInf,dtStoreinfo}=nextProps
        if(gtStoreInf==="1"){
            this.setState({
                store_name:dtStoreinfo.name,
                profilepic:dtStoreinfo.picture,
                store_location:dtStoreinfo.address,
                stock_managed:dtStoreinfo.stock_managed

            })
           
        }
    }
    componentDidMount(){
        initGAPg()
        if(this.props.getingCcp!=="1"){
            this.props.GetCCPAccount()
        }
    }
    handleChange(e){
        const { name, value} = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        Event("STORE_PROFILE", "EDIT_STORE_PROFILE","SUBMIT_EVENT")
        let store={name:this.state.store_name,address:this.state.store_location,stock_managed:this.state.stock_managed}
        this.props.UpdateStore(store)
    }
    render(){
        const {gtStoreInf,UpdatingStore,getingCcp,getCcpData,dataProfile}=this.props
        if(!dataProfile.is_store){
            return <Redirect to="/settings/user"/>
        }
        let hasPayment=false
        if(getingCcp==="1" && getCcpData){
            if(getCcpData.count>0){
                hasPayment=true
            }
        }
        return(
            <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp3">
                {UpdatingStore==="0" && <Loader/>}
                {(gtStoreInf==="0" || getingCcp==="0") ? <LoaderStore/> :
                <div>
                    <div className="MrAot StwdMaxC TxtCn">
                        <div className="StWdpic StBr60 HdOvrfl MrAot">
                            <img className="StWdpic" src={this.state.profilepic?this.state.profilepic : "/assets/img/default_user.png"} alt={this.state.store_name}/>
                        </div>
                        <div className="stbd2">
                            <ImgCropper
                                namebtn={Translate("profile","editphoto")}
                                name="img-crop"
                                tabtitle={Translate("profile","titlecrop")}
                                fromSrc={true}
                            />
                        </div>
                    </div>
                    <form method="post" onSubmit={this.handleSubmit}>
                        <div className="StMrtp" style={{padding:"25px 2%"}}>
                            <div className="InFlx flxDrc responsFlx FlWd">
                                <div className="Hlwd">
                                    <GroupeForm 
                                        id={'store_name'}
                                        name={'store_name'}
                                        placeholder={Translate("auths","storename")} 
                                        text={Translate("auths","storename")}   
                                        type={'text'}
                                        value={this.state.store_name}
                                        readOnly
                                        disabled
                                        // workfun={this.handleChange}
                                    />
                                </div>
                                <div className="Hlwd Mrg2">
                                    <GroupeForm 
                                        id={'store_location'}
                                        name={'store_location'}
                                        placeholder={Translate("auths","storelocation")} 
                                        text={Translate("auths","storelocation")}   
                                        type={'text'}
                                        value={this.state.store_location}
                                        workfun={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="FlWd InFlx flxDrc StMrtp">
                                {/*<div className="Hlwd">
                                    <SelectForm
                                        id={"stock_management"}
                                        name={"stock_managed"}
                                        option={[{text:Translate("auths","managedby"),val:true,selct:this.state.stock_managed},{text:"managed by me",val:false,selct:!this.state.stock_managed}]}
                                        text={Translate("auths","stockmanagement")} 
                                        workfun={this.handleChange} 
                                    />
        </div>*/}
                                <div className="Hlwd">
                                    <div className="grp-fm StdirLn StFnt">
                                        <div className="SsGrpRm RlPs">
                                            <span>{Translate("profile","paymenntinfo")}</span>  
                                        </div>
                                        <div className="SsGrpRm StMrg RlPs">
                                            <div className="InFlx flxDrc responsFlx FlWd" style={{height:"53px"}}>
                                                <div className="FlWd FrInp StBrdRd">
                                                    <InlinedElement 
                                                        first={ <div style={{height:"22px"}}>
                                                            <img alt="CCP logo" src="/assets/img/eccp.png"/>
                                                        </div>}
                                                        secnd={<span>CCP</span>}
                                                        leftElm={<Link to="/settings/store/payement?method=ccp"><span className={"IcnSiz InFlx AlgnItm JstfCnt StBr6 CrsPoi "+(hasPayment ? "TxtScc" : "BdgBlcl" )}>{hasPayment ? '✓' : '+'}</span></Link>}
                                                    /> 
                                                </div>
                                               {/*<div className="Hlwd Mrg2 FrInp StBrdRd">
                                                    <InlinedElement 
                                                        first={<div className="InFlx StBlFil">
                                                            {BankSvg}
                                                        </div>}
                                                        secnd={<span>{Translate("profile","bank")}</span>}
                                                        leftElm={<span className="IcnSiz InFlx AlgnItm JstfCnt StBr6 CrsPoi TxtScc">✓</span>}
                                                    /> 
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="MrAot StMarMx StMrtp" style={{width:"200px"}}>
                            <Button 
                                BtnText={Translate("orders","save")}
                            />
                        </div>
                    </form>
                </div>
                }
            </div>
        )
    }
    
}
function mapState(state) {
    const {gtStoreInf,dtStoreinfo,UpdatingStore,dataUpdate,dataProfile}=state.user
    const {getingCcp,getCcpData}=state.paymentMethod
    return { gtStoreInf,dtStoreinfo,UpdatingStore,dataUpdate,getingCcp,getCcpData,dataProfile };
}

const actionCreators = {
    UpdateStore:userActions.UpdateStore,
    GetCCPAccount:paymentActions.GetCCPAccount
};

const connectedStoreInfo = connect(mapState, actionCreators)(StoreInfo);
export { connectedStoreInfo as StoreInfo };