import { shopConstants } from "../constants";
import { DataShop } from "../types";

interface State {
  dataShop: DataShop | null;
  shopLoading: boolean;
  shopError: any;
  productShopPhoto: {
    url: string;
    id: string;
  } | null;
}

export function shop(state: State, action: any) {
  switch (action.type) {
    case shopConstants.GET_SHOP_REQUEST:
      return {
        ...state,
        shopLoading: true,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.GET_SHOP_SUCCESS:
      return {
        ...state,
        shopLoading: false,
        dataShop: action.payload,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.GET_SHOP_FAILURE:
      return {
        ...state,
        shopLoading: false,
        shopError: action.payload,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };

    case shopConstants.UPDATE_SHOP_REQUEST:
      return {
        ...state,
        shopLoading: true,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        shopLoading: false,
        dataShop: action.payload,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.UPDATE_SHOP_FAILURE:
      return {
        ...state,
        shopLoading: false,
        shopError: action.payload,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };

    case shopConstants.ADD_PRODUCT_SHOP_PHOTO_REQUEST:
      return {
        ...state,
        shopLoading: true,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.ADD_PRODUCT_SHOP_PHOTO_SUCCESS:
      return {
        ...state,
        shopLoading: false,
        shopError: null,
        productShopPhoto: action.payload,
        deletedShopPhoto: null,
      };
    case shopConstants.ADD_PRODUCT_SHOP_PHOTO_FAILURE:
      return {
        ...state,
        shopLoading: false,
        shopError: action.payload,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.DELETE_PRODUCT_SHOP_PHOTO_REQUEST:
      return {
        ...state,
        shopLoading: true,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };
    case shopConstants.DELETE_PRODUCT_SHOP_PHOTO_SUCCESS:
      return {
        ...state,
        shopLoading: false,
        shopError: null,
        productShopPhoto: null,
        deletedShopPhoto: action.payload,
      };
    case shopConstants.DELETE_PRODUCT_SHOP_PHOTO_FAILURE:
      return {
        ...state,
        shopLoading: false,
        shopError: action.payload,
        productShopPhoto: null,
        deletedShopPhoto: null,
      };

    default:
      return { ...state };
  }
}
