import React,{Component} from 'react'
import { connect } from 'react-redux'
import ContainerPage from '../_Common/ContainerPage'
import Table from '../_Common/Table'
import GroupeBtn from '../_Common/GroupeBtn'
import {Translate} from '../../../utils/lang/translate'
import {AddSvg,ReaperSvg,InLinkSvg} from '../_Common/IconSvg'
import { productActions } from '../../../actions'
import BtnLg from '../_Common/BtnLg'
import TableLoader from '../_Common/TableLoader'

class UnlinkProduct extends Component{

    componentWillReceiveProps(nextProps){
        
    }
    componentDidMount() {
        document.title = 'Maystro Delivery - '+Translate("titles","unlinkproduct");
    }
    render(){
        const {GetingUnlinkPrd,GetingUnlinkPrdData}=this.props
        const AddproductBtn=(
            <span className="CrsPoi"><BtnLg icon={ReaperSvg} text={Translate("product","addprodct")}/></span>
        )
        let bodytab=[]
        if(GetingUnlinkPrd==="1"){
            bodytab=GetingUnlinkPrdData.list.map(elem=>{
                return ['display_id',<div className="Inlflx"><img alt="shopify product" className="StBrdRdS" style={{height:"50px"}} src="https://cdn.shopify.com/s/files/1/0019/6922/5789/products/nnnn_copy.jpg?v=1569751706"/><span className="RlPs" style={{margin:"0 8px"}}>logistical s  jgh s shjg sdgjh sjhg s sjhg sdjgj dsgjhg sdjg sqdjq sjgqjd jghqs djhg q sqjdgdqsdj g_description sdq qsd qs  qsq </span></div>,'sd','sd',<GroupeBtn data_btn={[{type:"BDgInpc",action:()=>this.actionclick("/products/availibal/"),svgbtn:InLinkSvg},{type:"BDgInpc",action:() => this.actionclick("/products/request/"),svgbtn:AddSvg}]}/>]
            })
        }
        return(
        <ContainerPage page_title={Translate("titles","unlinkproduct")} data_top={AddproductBtn}>
            {GetingUnlinkPrd==="0" ? <TableLoader/>:
                <Table
                thead_elem={[Translate("product","extrnalid").toUpperCase(),Translate("product","productname").toUpperCase(),Translate("product","source").toUpperCase(),Translate("product","creatat").toUpperCase(),Translate("product","action").toUpperCase()]}
                tbody_elem={bodytab}
                />
            }
        </ContainerPage>
        )
    }
}
function mapState(state) {
    const {GetingUnlinkPrd,GetingUnlinkPrdData}=state.UnlinkProduct
    return {GetingUnlinkPrd,GetingUnlinkPrdData}
}

const actionCreators = {
    GetUnlinkedProduct:productActions.GetUnlinkedProduct
};

const connectedUnlinkProduct = connect(mapState, actionCreators)(UnlinkProduct);
export { connectedUnlinkProduct as UnlinkProduct };