export const date_difference = (date1, date2) => {
  let dateFuture = new Date(date1);
  let pastDate = new Date(date2);

  let difference=dateFuture - pastDate;
  let seconds = Math.floor((dateFuture - pastDate) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds=seconds-(minutes*60+hours*3600+days*24*3600)
  return { days, hours, minutes, seconds,difference };
};
