import React,{ Component } from 'react';
import { connect } from 'react-redux';
import {userActions} from '../../../actions'
import GroupeForm from '../../_Common/GroupeForm'
import Loader from '../../_Common/Loader'
import HeadLog from '../_Common/HeadLog'
import ContainerLog from '../_Common/ContainerLog'
import Button from '../../_Common/Button'
import {Translate} from '../../../utils/lang/translate'
import {Link, Redirect} from 'react-router-dom'
class JoinStore  extends Component{
    constructor(props) {
        super(props);
        let state={}
        const {Sended,user}=this.props
        if(Sended==="0" || user){
            state.user_name=user.user_name
            state.full_name=user.full_name
            state.email=user.email
            state.phone=user.phone
            state.password=user.password
            state.confirmed_password=user.confirmed_password
        }else{
            this.props.history.push('/register')
        }
        this.state = state
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
        document.title = 'Maystro Delivrey - '+Translate("titles","joinstore");
        if(this.props.user && this.props.user.phone){
            this.props.VerifyMember({phone:this.props.user.phone})
        }
    }
    componentWillReceiveProps(nextProps){
        const {VeryMember,dataVerifyMember}=nextProps;
        if(this.props.VeryMember!=="2" && VeryMember==="2" && dataVerifyMember && dataVerifyMember.data && dataVerifyMember.data["invitation does not exist"]){

            setTimeout(()=> this.props.history.push("/register"),5000)

        }

    }
    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        const {VeryMember,dataVerifyMember}=this.props
        if(VeryMember==="1" && dataVerifyMember && this.state.store_code){
            let member = {user_id:dataVerifyMember.user_id,team:dataVerifyMember.team,token:parseInt(this.state.store_code),user_name:this.state.user_name,full_name:this.state.full_name,email:this.state.email,phone:this.state.phone,password:this.state.password,confirmed_password:this.state.confirmed_password,team:dataVerifyMember.team} 
            this.props.CreateMember(member)
        }
    }
    render(){
        const {CrtingMember,VeryMember,dataVerifyMember}=this.props
        if(CrtingMember==="1"){
            return <Redirect to="/login"/>
        }else if(CrtingMember==="2"){
            return <Redirect to="/register"/>
        }
        return(VeryMember!=="2"?
            <ContainerLog>
                {(CrtingMember==="0" || VeryMember==="0") && <Loader/>}
                <HeadLog
                    titleHead={Translate("auths","storeteam")}
                    descHead={Translate("auths","textjoin")}
                />
                <form action="post" onSubmit={this.handleSubmit} className="StMarMx">
                    <GroupeForm 
                        id={"store_code"}
                        name={"store_code"}
                        placeholder={Translate("auths","storecode")}
                        text={Translate("auths","storecode")}   
                        type={"password"}
                        value={this.state.store_code}
                        workfun={this.handleChange}
                    />
                    <div className="SmtGrp MrAot StMarMx">
                        <Button 
                            BtnText={Translate("auths","finish")}
                        />
                    </div>
                </form>
                <div className="StOpcVal">
                    <div className="StMrg">
                        <span className="StWdDst">
                            {Translate("auths","ownstore")}
                        </span>
                        <Link className="StBle stbold StWdDst" to="/store/add">
                            {Translate("auths","enterinfo")}
                        </Link>
                    </div>
                </div>
            </ContainerLog>:
            <ContainerLog>
                {false && <HeadLog
                    titleHead={Translate("auths","storeteam")}
                    descHead={Translate("auths","textjoin")}
                />}
                {dataVerifyMember && dataVerifyMember.data && dataVerifyMember.data["invitation does not exist"] &&
                <div>
                    <p className="StRedClr stbold">{Translate("alert","failfindinvitedmember")}</p>
                    <p className="">{"You'll be automatically redirected to the concerning page in 5 seconds ..."}</p>

                </div>
                }
                
            </ContainerLog>
        )
    }
}
function mapState(state) {
    const {Sended,user}=state.registration 
    const {CrtingMember,dataCreateMember,VeryMember,dataVerifyMember}=state.memberTeam
    return {Sended,user,CrtingMember,dataCreateMember,VeryMember,dataVerifyMember}
}

const actionCreators = {
    CreateMember:userActions.CreateMember,
    VerifyMember:userActions.VerifyMember
};

const connectedJoinStore = connect(mapState, actionCreators)(JoinStore);
export { connectedJoinStore as JoinStore };