import React from "react";
import { isArray } from "jquery";
export default function SelectForm(props) {
  return (
    <div className="grp-fm StdirLn StFnt">
      <div className="SsGrpRm RlPs">
        <label htmlFor={props.id}>{props.text}</label>
        <div className="StAbs StLanNl StTpNl StOpcVal">{props.more}</div>
      </div>
      <div className="SsGrpRm StMrg RlPs">
        <select id={props.id} autoComplete="off" name={props.name} className={"FntfMin FlWd Fntcl RgSel StdirLn FrInp StSizLn StHgInp StBrdRd StOpcVal " + (props.stclass ? props.stclass : "")} onChange={props.workfun}>
          {props.loading && <option selected={true}>{"Loading"}</option>}
          {!props.loading && isArray(props.option)
            ? props.option.map((opt) => {
                const { text, val } = opt;
                return (
                  <option key={val} value={val} selected={props.value === val}>
                    {text}
                  </option>
                );
              })
            : ""}
        </select>
      </div>
    </div>
  );
}
