import React from "react"
import ContentLoader from "react-content-loader"
const TeamLoader = (props) => (
    <div className="StWdpwrp BrdBl StBrdRd SthgMaxC">
    <ContentLoader 
        speed={2}
        height={100}
        width={"100%"}
        viewBox="0 0 500 110"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
        <rect x="10" y="5" rx="0" ry="0" width="75%" height="25" />
        <rect x="10" y="40" rx="0" ry="0" width="60%" height="25" />
        <rect x="10" y="75" rx="0" ry="0" width="90%" height="30" />
    </ContentLoader>
  </div>
  )
  
  export {TeamLoader}
  
  const ViewteamLoder = (props) => (
    <div className="StWdpwrp StBrdRd SthgMaxC">
    <ContentLoader 
        speed={2}
        height={100}
        width={"100%"}
        viewBox="0 0 500 110"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
        <rect x="10" y="5" rx="0" ry="0" width="25%" height="20" />
        <rect x="10" y="30" rx="0" ry="0" width="80%" height="35" />
        <rect x="10" y="70" rx="0" ry="0" width="100%" height="40" />
    </ContentLoader>
  </div>
  )
  
  export {ViewteamLoder}