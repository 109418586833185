export const dashboardConstants = {
    GET_STATUS_STORE_SUCCESS: 'GET_STATUS_STORE_SUCCESS',
    GET_STATIC_STATUS_STORE_SUCCESS: 'GET_STATIC_STATUS_STORE_SUCCESS',
    GET_STATUS_STORE_FAILURE: 'GET_STATUS_STORE_FAILURE',
    GET_STATIC_STATUS_STORE_FAILURE: 'GET_STATIC_STATUS_STORE_FAILURE',
    GET_STATUS_STORE_REQUEST: 'GET_STATUS_STORE_REQUEST',
    GET_STATIC_STATUS_STORE_REQUEST: 'GET_STATUS_STORE_REQUEST',

    GET_TODAY_STATUS_STORE_SUCCESS: 'GET_TODAY_STATUS_STORE_SUCCESS',
    GET_TODAY_STATUS_STORE_FAILURE: 'GET_TODAY_STATUS_STORE_FAILURE',
    GET_TODAY_STATUS_STORE_REQUEST: 'GET_TODAY_STATUS_STORE_REQUEST',

    GET_TOP_PRODUCT_SUCCESS: 'GET_TOP_PRODUCT_SUCCESS',
    GET_TOP_PRODUCT_FAILURE: 'GET_TOP_PRODUCT_FAILURE',
    GET_TOP_PRODUCT_REQUEST: 'GET_TOP_PRODUCT_REQUEST',

};