import React,{Component} from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../utils/lang/translate'
import {ImgCropper} from '../../_Common/ImgCropper'
import Button from '../../_Common/Button'
import {userActions,alertActions} from '../../../actions'
import Loader from '../../_Common/Loader'
import GroupeForm from '../../_Common/GroupeForm'
class UserInfo extends Component{
    constructor(props){
        super(props)
        this.state={
            full_name: '',
            phone: '',
            oldpassword:'',
            password:'',
            confpassword:''
        }
        this.handleChange=this.handleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentDidMount(){
        if(this.props.GetingUser==="1" && this.props.dataProfile){
            this.setState({
                full_name: this.props.dataProfile.full_name,
                phone: this.props.dataProfile.phone
            })
  
        }
    }
    componentWillReceiveProps(nextProps){
        const {chngPass,GetingUser,dataProfile}=nextProps
        if(this.props.chngPass==="0" && chngPass==="2"){
            this.props.SendAlert('50',Translate('alert','updtpsfail'))
        }
        if(this.props.chngPass==="0" && chngPass==="1"){
            this.props.SendAlert('41',Translate('alert','updpasssuc'))
        }
        if(GetingUser==="1" && dataProfile){
            this.setState({
                full_name: dataProfile.full_name,
                phone: dataProfile.phone
            })
  
        }
    }
    handleChange(e){
        const { name, value} = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        // let user={old_password:this.state.oldpassword,password:this.state.password,confirmed_password:this.state.confpassword}
        // this.props.ChangePassword(user)
    }
    render(){
        const {GetingUser,dataProfile}=this.props
        let picture
        if(GetingUser==="1" && dataProfile){
            picture=dataProfile.picture
        }
        return(
            <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
                {this.props.chngPass==="0" && <Loader/>}
                <div className="stbd2">
                    <h3 className="DlMg">{Translate('settings','userinfo')}</h3>
                </div>
                <div className="MrAot FlWd TxtCn">
                    <div className="StWdpic StBr60 HdOvrfl MrAot">
                        <img alt="user profile" src={picture?picture : "/assets/img/default_user.png"}/>
                    </div>
                    <div className="stbd2">
                        <ImgCropper
                            namebtn={Translate("profile","edtpic")}
                            name="img-crop"
                            tabtitle={Translate("profile","titlecrop")}
                        />
                    </div>
                    <div className="MrAot Hlwd" style={{width:"80%",padding:"10px 0"}}>
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="InFlx flxDrc responsFlx FlWd spctwB">

                                <div className="Hlwd">
                                    <GroupeForm 
                                        id={'full_name'}
                                        name={'full_name'}
                                        placeholder={Translate("auths","fullname")} 
                                        text={Translate("auths","fullname")}   
                                        type={'text'}
                                        value={this.state.full_name}
                                        workfun={this.handleChange}
                                    />
                                </div>
                                <div className="Hlwd">
                                    <GroupeForm 
                                        id={'phone'}
                                        name={'phone'}
                                        placeholder={Translate("auths","phone")} 
                                        text={Translate("auths","phone")}   
                                        type={'number'}
                                        value={this.state.phone}
                                        workfun={this.handleChange}
                                        readOnly
                                        disabled
                                    />
                                    
                                </div>
                            </div>
                            
                            <div className="MrAot StMarMx StMrtp" style={{width:"200px"}}>
                                <Button 
                                    BtnText={Translate("orders","save")}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    
}
function mapState(state) {
     const {GetingUser,dataProfile,chngPass,chngPassData}=state.user
     return {GetingUser,dataProfile,chngPass,chngPassData}
}

const actionCreators = {
    ChangePassword:userActions.ChangePassword,
    SendAlert:alertActions.SendAlert
};

const connectedUserInfo = connect(mapState, actionCreators)(UserInfo);
export { connectedUserInfo as UserInfo };