import React,{Component} from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../utils/lang/translate'
import GroupeForm from '../../../_Common/GroupeForm'
import {paymentActions} from '../../../../actions'
import Button from '../../../_Common/Button'
import Loader from '../../../_Common/Loader'
import { Redirect,Link } from 'react-router-dom'
import {initGAPg,Event } from '../../_Common'
class Paymenntinfo extends Component{
    constructor(props){
        super(props)
        let initialstate={
            fullname:'',
            address:'',
            accountnumber:'',
            keyAccount:'',
            accountrip:''
        }
        const {getingCcp,getCcpData}=this.props
        if(getingCcp==="1" && getCcpData && getCcpData.count>0){
            initialstate={
                fullname:getCcpData.results[0].full_name,
                address:getCcpData.results[0].address,
                accountnumber:getCcpData.results[0].account_number,
                keyAccount:getCcpData.results[0].key,
                accountrip:getCcpData.results[0].account_rip,
                ccpid:getCcpData.results[0].id
            }
        }else if(!getingCcp){
            this.props.history.push("/settings/store")
        }
        this.state=initialstate
        this.handleChange=this.handleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentDidMount(){
        initGAPg()
    }
    handleChange(e){
        const { name, value} = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        let ccpacount={full_name:this.state.fullname,address:this.state.address,key:this.state.keyAccount,account_number:this.state.accountnumber,account_rip:this.state.accountrip}
        if(this.props.getingCcp==="1" && this.props.getCcpData && this.props.getCcpData.count>0){
            Event("STORE_CCP", "EDIT_CCP_ACCOUNT","CLICK_EVENT")
            this.props.UpdateCCPAccount(ccpacount,this.state.ccpid)
        }else{
            Event("STORE_CCP", "ADD_CCP_ACCOUNT","CLICK_EVENT")
            this.props.AddCCPAcount(ccpacount)
        }
    }
    render(){
        const {getingCcp,getCcpData,updatingCcp,addingCcp}=this.props
        if(addingCcp==="1" || updatingCcp==="1"){
            return <Redirect to="/settings/store"/>
        }
        return(
            <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs sTbxSz StPdsml">
                {(addingCcp==="0" || updatingCcp==="0") && <Loader/>}
                <div className="stbd2">
                    <h3 className="DlMg">
                        <div className="StDrc">
                            <Link className="StOpcVal" to="/settings/store">
                                {Translate('settings','storeinfo')}
                            </Link>
                            <div className="Inlflx StWdDst">
                                {Translate("orders","flech")}
                            </div>
                            <div className="Inlflx">
                                {Translate('profile','paymenntinfo')}
                            </div>
                        </div>
                    </h3>
                </div>
                <div>
                    <form method="post" onSubmit={this.handleSubmit}>
                        <div className="MrAot" style={{width:"85%"}}>
                            <div className="FlWd InFlx flxDrc StMrtp">
                                <div className="Hlwd">
                                    <GroupeForm 
                                        id={'fullname'}
                                        name={'fullname'}
                                        placeholder={Translate("auths","fullname")} 
                                        text={Translate("auths","fullname")}   
                                        type={'text'}
                                        value={this.state.fullname}
                                        workfun={this.handleChange}
                                    />
                                </div>
                                <div className="Hlwd Mrg2">
                                    <GroupeForm 
                                        id={'address'}
                                        name={'address'}
                                        placeholder={Translate("payement","adress")} 
                                        text={Translate("payement","adress")}   
                                        type={'text'}
                                        value={this.state.address}
                                        workfun={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="FlWd InFlx flxDrc StMrtp">
                                <div className="Hlwd InFlx flxDrc">
                                    <div style={{width:"70%"}}>
                                        <GroupeForm 
                                            id={'accountnumber'}
                                            name={'accountnumber'}
                                            placeholder={Translate("payement","accountnumber")} 
                                            text={Translate("payement","accountnumber")}   
                                            type={'text'}
                                            pattern={"[0-9]+"}
                                            value={this.state.accountnumber}
                                            workfun={this.handleChange}
                                        />
                                    </div>
                                    <div className="Mrg2" style={{width:"27%"}}>
                                        <GroupeForm 
                                            id={'keyAccount'}
                                            name={'keyAccount'}
                                            placeholder={Translate("payement","key")} 
                                            text={Translate("payement","key")}   
                                            type={'text'}
                                            pattern={"[0-9]+"}
                                            value={this.state.keyAccount}
                                            workfun={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="Hlwd Mrg2">
                                    <GroupeForm 
                                        id={'Accountrip'}
                                        name={'accountrip'}
                                        placeholder={Translate("payement","accountrip")} 
                                        text={Translate("payement","accountrip")}   
                                        type={'text'}
                                        pattern={"[0-9]+"}
                                        value={this.state.accountrip}
                                        workfun={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="MrAot StMarMx StMrtp" style={{width:"200px"}}>
                            <Button 
                                BtnText={(getingCcp==="1" && getCcpData && getCcpData.count>0) ?Translate("orders","save") :Translate("settings","addacount")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    
}
function mapState(state) {
    const {addingCcp,CcpData,getingCcp,getCcpData,updatingCcp,CcpUpdateData}=state.paymentMethod
    return { addingCcp,CcpData,getingCcp,getCcpData,updatingCcp,CcpUpdateData };
}

const actionCreators = {
    AddCCPAcount:paymentActions.AddCCPAcount,
    UpdateCCPAccount:paymentActions.UpdateCCPAccount
};

const connectedPaymenntinfo = connect(mapState, actionCreators)(Paymenntinfo);
export { connectedPaymenntinfo as Paymenntinfo };