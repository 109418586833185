import React from "react";
import PropTypes from "prop-types";
import InlinedElement from "../../_Common/InlinedElement";
import {
  CheckSvg,
  CloseSvg,
  ExcelSvg,
  FileDownSvg,
  FilterSvg,
  MoreHDotsSvg,
  MoreHSvg,
  OutSvg,
  PickupSvg,
  PlayListAddSvg,
  PrintSvg,
  RefreshSvg,
} from "../../_Common/IconSvg";
import { Translate } from "../../../../utils/lang/translate";
import CheckBoxForm from "../../../_Common/CheckBoxForm";
import SearchInputType from "../../_Common/SearchInputType";
import GroupeBtnSp from "../../_Common/GroupeBtnSp";
import { Link } from "react-router-dom";
import { Event } from "../../_Common";
import FilterElement from "./FilterElement";
import DropDown from "../../_Common/DropDown";

// const  OrdersFiltersToolBar= React.forwardRef((props, ref)=> {
class  OrdersFiltersToolBar extends React.Component {

  constructor(props){
    super(props)

    this.showFilter=this.showFilter.bind(this)
    this.handleClickOutsideFilter=this.handleClickOutsideFilter.bind(this)

  }
  showFilter(){
    Event("ORDERS", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", this.handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }
  };
  handleClickOutsideFilter(event){
    const {binder}=this.props
    if (
      binder.FilterRef &&
      binder.FilterRef.current &&
      !binder.FilterRef.current.contains(event.target)
    ) {
      this.showFilter();
    }
  };


  render(){
    const { binder, dtStoreinfo, productsData, wilayasData, communesData } =
      this.props;
    return (
      <>
        <div
          className="InFlx flxDrc responsFlx StmpB3 StPs zindX2 BdgGc"
          style={{ height: "48px", padding: "7px 0", top: "-20px" }}
        >
          <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
            <div className="FlHg ClSidTh StBrdRdS StMrtg2" ref={binder.FilterRef}>
              <div className="CrsPoi FlHg" onClick={this.showFilter}>
                <InlinedElement
                  secnd={<div className="IcnSiz">{FilterSvg}</div>}
                />
              </div>
              <div
                id="Filter_container"
                className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                style={{ width: "350px", maxWidth: "90vw" }}
              >
                <div id="Sub_Container_filter">
                  <div style={{ marginTop: "8px" }}>
                    <div className="RlPs">
                      <InlinedElement
                        secnd={<strong>{Translate("orders", "filter")}</strong>}
                        leftElm={
                          <div
                            onClick={this.showFilter}
                            className="InFlx CrsPoi IcnSizSm"
                          >
                            {CloseSvg}
                          </div>
                        }
                        style={{ width: "100%", padding: "0px" }}
                      />
                    </div>
                    {binder.props.location.search &&
                    (binder.props.location.search.replace(
                      "?page=" + binder.props.page,
                      ""
                    ) != "" ||
                      binder.props.location.search.replace(
                        "&page=" + binder.props.page,
                        ""
                      )) != "" ? (
                      <span
                        className="CrsPoi StBle DsBlc StdirLn"
                        onClick={binder.resetFilter}
                      >
                        {Translate("orders", "resetfilter")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <FilterElement
                    ref={binder.refrs.Status_Container}
                    container="Status_Container"
                    title={Translate("orders", "orderstatus")}
                    handler={() => binder.ControlFilter("Status_Container")}
                  >
                    {binder.state.statusList.map((elem, key) => {
                      let el = elem.replace("p", "");
                      return (
                        <CheckBoxForm
                          key={key}
                          id={"S" + elem}
                          name={"S" + elem}
                          text={Translate("statusorder", elem)}
                          workfun={() => binder.manageQueryStatus(el)}
                          check={
                            Array.isArray(binder.props.status) &&
                            binder.props.status.indexOf(el) != -1
                          }
                        />
                      );
                    })}
                  </FilterElement>
                  <FilterElement
                    ref={binder.refrs.product_Container}
                    container="product_Container"
                    title={Translate("product", "productname")}
                    handler={() => binder.ControlFilter("product_Container")}
                  >
                    <SearchInputType
                      nameOption="products"
                      NameAffect="display_id"
                      NameShow="logistical_description"
                      CheckedValues={binder.props.products}
                      options={productsData ? productsData.list : []}
                      placeholder={Translate("product", "productname")}
                      onChangeSearch={binder.setOptionChnage}
                    />
                  </FilterElement>
                  <FilterElement
                    ref={binder.refrs.wilaya_Container}
                    container="wilaya_Container"
                    title={Translate("product", "wilaya")}
                    handler={() => binder.ControlFilter("wilaya_Container")}
                  >
                    <SearchInputType
                      nameOption="wilaya"
                      NameShow={1}
                      NameAffect={0}
                      CheckedValues={binder.props.wilaya}
                      options={wilayasData}
                      placeholder={Translate("product", "wilaya")}
                      onChangeSearch={binder.setOptionChnage}
                    />
                  </FilterElement>
                  <FilterElement
                    ref={binder.refrs.commune_Container}
                    container="commune_Container"
                    title={Translate("orders", "district")}
                    handler={() => binder.ControlFilter("commune_Container")}
                  >
                    <SearchInputType
                      nameOption="commune"
                      NameShow="name"
                      NameAffect="id"
                      CheckedValues={binder.props.commune}
                      options={communesData}
                      placeholder={Translate("orders", "district")}
                      onChangeSearch={binder.setOptionChnage}
                    />
                  </FilterElement>
                </div>
              </div>
            </div>
            <form onSubmit={binder.SetDisplayId}>
              <div className="FlHg StBrdRdS ClSidTh">
                <InlinedElement
                  style={{ padding: "0" }}
                  secnd={
                    <div>
                      <input
                        type="text"
                        autoComplete="off"
                        onChange={binder.ChangeDisId.bind(binder)}
                        name="filterbyid"
                        style={{ width: "300px",maxWidth:"40vw" }}
                        className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                        value={binder.props.display_id}
                        placeholder={Translate("orders", "filterbyid")}
                      />
                    </div>
                  }
                  leftElm={
                    <button
                      className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                      style={{ padding: "10px", margin: "0 5px" }}
                    >
                      {binder.props.display_id ? OutSvg : CheckSvg}
                    </button>
                  }
                />
              </div>
            </form>
          </div>
          <div className="StAutMr d-large" ref={binder.ExpRef}>
            <div
              className="Inlflx AlgnItm StDrc StFlxStr FlWd"
              style={{ height: "48px" }}
            >
              <GroupeBtnSp
                style={{ margin: "0", zoom: "1.2" }}
                data_btn={[
                  {
                    type: "BDgInpc",
                    action: binder.RefreshOredr.bind(binder),
                    svgbtn: RefreshSvg,
                    tooltip: Translate("tooltips", "refresh")
                    
                  },
                  binder.selectIdlist.length > 0 || binder.state.allSelect
                    ? {
                        type: "BDgInpc",
                        action: () =>
                          binder.EditOrder("/print/facture/boncm", true),
                        svgbtn: PrintSvg,
                        tooltip: Translate("tooltips", "printorderslip")
                      }
                    : {},
                  (binder.selectIdlist.length > 0 || binder.state.allSelect) &&
                  !dtStoreinfo.stock_managed
                    ? {
                        type: "BDgInpc",
                        action: () => binder.CreatePickup(),
                        svgbtn: PickupSvg,
                        title: Translate("pickups", "addpickup"),
                        tooltip: Translate("tooltips", "createpickup"),
                      }
                    : {},
                  {
                    type: "BDgInpc",
                    action: binder.controlExport,
                    svgbtn: PlayListAddSvg,
                  },
                  {
                    type: "BDgInpc",
                    action: binder.controlMore,
                    svgbtn: MoreHSvg,
                  },
                ]}
              />
            </div>
            <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
              <div
                id="List_Export"
                className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
                style={{ height: "0px" }}
              >
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => binder.CallExportOrder("csv")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {FileDownSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordcsv")}</div>}
                  />
                </div>
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => binder.CallExportOrder("xlsx")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {ExcelSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordxsl2007")}</div>}
                  />
                </div>
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => binder.CallExportOrder("xls")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {ExcelSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordxsl97")}</div>}
                  />
                </div>
              </div>
            </div>
            <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
              <div
                id="List-more-Ac"
                className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
                style={{ height: "0px" }}
              >
                <Link
                  to="/order/import"
                  className="CrsPoi"
                  style={{ height: "55px" }}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {ExcelSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "importorder")}</div>}
                  />
                </Link>
              </div>
            </div>
  
            
          </div>
        </div>
          {/* ********************************************* */}
          {/* *************Mobile only************** */}
          {/* ********************************************* */}
          <div className="d-sm InFlx flxDrc spcEvn">
              {(binder.selectIdlist.length > 0 || binder.state.allSelect) &&
              <div className={"Inlflx StWdDst StBrdRdS stBdp CrsPoi BDgInpc"}  
              onClick={() =>binder.EditOrder("/print/facture/boncm", true)}>
                          {PrintSvg}
              </div>}
              {(binder.selectIdlist.length > 0 || binder.state.allSelect) &&
                  !dtStoreinfo.stock_managed &&
              <div className={"Inlflx StWdDst StBrdRdS stBdp CrsPoi BDgInpc"}  
              onClick={() => binder.CreatePickup()}>
                          {PickupSvg}
              </div>}
             
              <div>
  
                  <DropDown 
                  isOpen={false}
                  headerTitle={<GroupeBtnSp
                    style={{ margin: "0", zoom: "1.2" }}
                    data_btn={[{
                      type: "BDgInpc",
                      svgbtn: MoreHDotsSvg,
                    },]} 
                    />}
                  list={[
                      {icon: RefreshSvg, label: "Refresh", handler:binder.RefreshOredr.bind(binder)},
                      {icon: FileDownSvg, label: Translate("orders", "exportordcsv"), handler:() => binder.CallExportOrder("csv")},
                      {icon: FileDownSvg, label: Translate("orders", "exportordxsl2007"), handler:() => binder.CallExportOrder("xlsx")},
                      {icon: MoreHSvg, label: Translate("orders", "importorder"), handler:() => binder.props.history.push("/order/import")},
                     
                      ]}
                  />
              </div>
  
          </div>
  
       
      </>
    );
    
  }
  
}

OrdersFiltersToolBar.propTypes = {
  binder: PropTypes.object.isRequired,
  dtStoreinfo: PropTypes.object.isRequired,
  // productsData: PropTypes.array.isRequired,
  // wilayasData: PropTypes.array.isRequired,
  // communesData: PropTypes.array.isRequired,
};

export default OrdersFiltersToolBar;
