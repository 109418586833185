import React from "react";
import { Translate } from "../../../../utils/lang/translate";
import Button from "../../../_Common/Button";
import { CloseSvg } from "../../_Common/IconSvg";
import TabShow from "../../_Common/TabShow";
import { Event } from "../../_Common";

interface Props {
  isHidden: boolean;
  onClose: () => void;
}

const GetStartedPopup: React.FC<Props> = ({ isHidden, onClose }) => {
  return (
    <TabShow
      boxStyling={{ padding: "5px 40px", width: "auto" }}
      style={{ width: "800px" }}
      isHidden={isHidden}
      darkBg
    >
      <div className="FlWd Inlflx AlgnItm StDrc spcBtwn">
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("shop", "buildYourStoreTitle")}</h3>
        </div>
        <div
          onClick={() => {
            Event("SHOP", "SHOP_START_CLOSE", "CLICK_EVENT");
            onClose();
          }}
        >
          {CloseSvg}
        </div>
      </div>
      <div className="SmMarS2">
        <div>
          <p className="stfSize">{Translate("shop", "buildYourStoreText")}</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/3PZ65s2qLTE?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="MrAot" style={{ width: "88%" }}>
          <div
            className="DsplGrd MrAot StMrtp"
            style={{
              gridTemplateColumns: "1fr",
              gridColumnGap: "30px",
              width: "45%",
            }}
          >
            <Button
              onClick={() => {
                Event("SHOP", "SHOP_START_BUTTON", "CLICK_EVENT");
                onClose();
              }}
              BtnText={Translate("shop", "start")}
            />
          </div>
        </div>
      </div>
    </TabShow>
  );
};

export default GetStartedPopup;
