import React from "react";

import "./cssInput.css";

export default function DropDown(props) {
  const { options = [] } = props;
  const dataWarehousesaMap = options.map((data, index) => {
    return { key: data.id, value: data.name };
  });
  const listItems = dataWarehousesaMap.map((option, index) => {
    return (
      <option key={option.key} className="insideInput" value={option.value}>
        {option.value}
      </option>
    );
  });

  return (
    <div className=" ">
      <select
        value={props.valuee}
        onChange={props.onChange}
        className="input-password-mobile-small input-password-mobile input"
      >
        <option className="insideInput " value="" disabled>
          Warehouse
        </option>

        {listItems}
      </select>
    </div>
  );
}
