import React from "react";
import { Statistic } from "../../../types";
import { Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import StatisticsDisplayer from "./StatisticsDisplayer";
import VisitorsChart from "./VisitorsChart";

interface Props {
  websiteVisitors: Statistic[];
  data: number[];
  labels: string[];
}

const WebsiteVisitors: React.FC<Props> = ({
  websiteVisitors,
  data,
  labels,
}) => {
  return (
    <div className="WebsiteVisitors">
      <ContainerPage
        page_title={Translate("titles", "websiteVisitors")}
        small_title
        lineHeader
        subContainer
      >
        <StatisticsDisplayer statistics={websiteVisitors} />
        <VisitorsChart data={data} labels={labels} />
      </ContainerPage>
    </div>
  );
};

export default WebsiteVisitors;
