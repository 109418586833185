import { userConstants } from '../constants';

export function authentication(state, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loginIn:"0",
        data: action.payload
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loginIn:"1",
        data: action.payload
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loginIn:"2",
        data: action.payload
      };
    case userConstants.RESET_SMS_REQUEST:
      return {
        restsms:"0",
        restsmsData: action.payload
      };
    case userConstants.RESET_SMS_SUCCESS:
      return {
        restsms:"1",
        restsmsData: action.payload
      };
    case userConstants.RESET_SMS_FAILURE:
      return {
        restsms:"2",
        restsmsData: action.payload
      };
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        sendRest:"0",
        sendRestData: action.payload
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        sendRest:"1",
        sendRestData: action.payload
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        sendRest:"2",
        sendRestData: action.payload
      };
    case userConstants.LOGOUT:
      return {
        logout:"0",
      };
    default:
        return {}
    }
}