import React, { Component } from "react";
import { connect } from "react-redux";
import Button from '../../_Common/Button'
import GroupeForm from '../../_Common/GroupeForm'
import { Translate } from "../../../utils/lang/translate";
import { alertActions, userActions } from "../../../actions";
import {LockSvg} from "../_Common/IconSvg"
class LoginInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      oldpassword: "",
      password: "",
      confpassword: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    if(this.props.GetingUser==="1" && this.props.dataProfile){
      this.setState({
          username: this.props.dataProfile.username,
          email: this.props.dataProfile.email
      })

  }
  }
  componentWillReceiveProps(nextProps){
      const {chngPass,chngPassData,GetingUser,dataProfile}=nextProps
      if(GetingUser==="1" && dataProfile){
          this.setState({
              username: dataProfile.username,
              email: dataProfile.email
          })

      }

      if(this.props.chngPass==="0" && chngPass==="2" && chngPassData){
        this.props.SendAlert("41",Translate('alert','updpasssuc'))
      }
      if(this.props.chngPass==="0" && chngPass==="1"){
        this.props.SendAlert('41',Translate('alert','updpasssuc'))
      }

  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    e.preventDefault();
    let user={old_password:this.state.oldpassword,password:this.state.password,confirmed_password:this.state.confpassword}
    this.props.ChangePassword(user)
  }
  render() {
    return (
      <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
        <div className="stbd2">
          <h3 className="DlMg">{Translate("settings", "logininfo")}</h3>
        </div>
        {
          <div className="ClSidTh StBrdTb FlWd StBgbrds lytWdp3">
            <div
              className="MrAot Hlwd"
              style={{ width: "90%", padding: "10px 0" }}
            >
              <form method="post" autoComplete="off" onSubmit={this.handleSubmit}>
                  <div className="InFlx flxDrc responsFlx FlWd spctwB">

                    <div className="Hlwd">
                            <GroupeForm
                            id={"username"}
                            name={"username"}
                            placeholder={Translate("auths", "username")}
                            text={Translate("auths", "username")}
                            type={"text"}
                            value={this.state.username}
                            workfun={()=>null}
                            icon={LockSvg}
                            readOnly
                            disabled
                            />
                            <GroupeForm
                            id={"email"}
                            name={"email"}
                            placeholder={Translate("auths", "email")}
                            text={Translate("auths", "email")}
                            type={"email"}
                            value={this.state.email}
                            workfun={()=>null}
                            icon={LockSvg}
                            readOnly
                            disabled
                            />

                    </div>
                    <div className="Hlwd">

                        <GroupeForm
                        id={"oldpassword"}
                        name={"oldpassword"}
                        placeholder={Translate("auths", "oldpassword")}
                        text={Translate("auths", "oldpassword")}
                        type={"password"}
                        value={this.state.oldpassword}
                        workfun={this.handleChange}
                        />
                        <GroupeForm
                        id={"password"}
                        name={"password"}
                        placeholder={Translate("auths", "password")}
                        text={Translate("auths", "password")}
                        type={"password"}
                        value={this.state.password}
                        workfun={this.handleChange}
                        />
                        <GroupeForm
                        id={"confpassword"}
                        name={"confpassword"}
                        placeholder={Translate("auths", "confpassword")}
                        text={Translate("auths", "confpassword")}
                        type={"password"}
                        value={this.state.confpassword}
                        workfun={this.handleChange}
                        />
                    </div>
                  </div>
                <div
                  className="MrAot StMarMx StMrtp"
                  style={{ width: "200px" }}
                >
                  <Button BtnText={Translate("orders", "save")} />
                </div>
              </form>
            </div>
          </div>
        }
      </div>
    );
  }
}
function mapState(state) {
    const {chngPass,chngPassData,GetingUser,dataProfile}=state.user

    return {chngPass,chngPassData,GetingUser,dataProfile}

}

const actionCreators = {
    ChangePassword: userActions.ChangePassword,
    GetProfileUser: userActions.GetProfileUser,
    SendAlert:alertActions.SendAlert,
};

const connectedLoginInfo = connect(mapState, actionCreators)(LoginInfo);
export { connectedLoginInfo as LoginInfo };
