import React from 'react'
export default function BtnLg(props){
    return (
        <div className={"StwdMaxC FlHg StBrdRdS StTrns "+(props.classSet?props.classSet:"BdgBlcl")} style={props.style} disabled={props.disabled}>
            <div className="InFlx JstfCnt flxDrc FlHg BtNseti">
                {props.icon && <div className="FlHg">
                    <div className="TbDp RlPs FlHg">
                        <div className="TbCdp RlPs TxtCn VrAlg">
                            <div className="IcnSiz">
                                {props.icon}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="FlHg FlWd StWdDst">
                    <div className="TbDp RlPs FlHg FlWd">
                        <div className="TbCdp RlPs TxtCn VrAlg">
                            <span className="StSmlS DsBlc">{props.text}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}