import React from 'react';
export default function CheckBoxForm(props){
    return(
        <div className={props.rmMarg ? "":"StMrg"}>
            <div className="InFlx flxDrc AlgnItm">
                    <input onChange={props.workfun} 
                    type="checkbox" name={props.name} 
                    id={props.id} 
                    checked={props.check}
                    // style={{borderColor: props.error?"#ff4f4f":"inherit", borderWidth: props.error?"2px":"0"}}
                    />
                    <label 
                    className={props.labelClass} 
                    htmlFor={props.id}
                    style={{color: props.error?"#ff4f4f":"inherit"}}
                    >{props.text}
                    </label>
            </div>
        </div>
    )
}