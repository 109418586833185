export const exchangeConstants = {
    ADD_EXCHANGE_SUCCESS: 'ADD_EXCHANGE_SUCCESS',
    ADD_EXCHANGE_FAILURE: 'ADD_EXCHANGE_FAILURE',
    ADD_EXCHANGE_REQUEST: 'ADD_EXCHANGE_REQUEST',

    UPDATE_EXCHANGE_SUCCESS: 'UPDATE_EXCHANGE_SUCCESS',
    UPDATE_EXCHANGE_FAILURE: 'UPDATE_EXCHANGE_FAILURE',
    UPDATE_EXCHANGE_REQUEST: 'UPDATE_EXCHANGE_REQUEST',

    GET_EXCHANGE_SUCCESS: 'GET_EXCHANGE_SUCCESS',
    GET_EXCHANGE_FAILURE: 'GET_EXCHANGE_FAILURE',
    GET_EXCHANGE_REQUEST: 'GET_EXCHANGE_REQUEST',

    ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
    ADD_REFUND_FAILURE: 'ADD_REFUND_FAILURE',
    ADD_REFUND_REQUEST: 'ADD_REFUND_REQUEST',
};