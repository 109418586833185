import React from "react";

interface Props {
  svg: React.ReactElement;
  title: string;
  scale?: number;
  isActive?: boolean;
  isDone?: boolean;
  isDisabled?: boolean;
  handleClick: () => void;
}

const NavigationBubble: React.FC<Props> = ({
  svg,
  title,
  scale,
  isActive,
  isDone,
  isDisabled,
  handleClick,
}) => {
  const customClass = isActive ? "active" : isDone ? "done" : "";
  const scaleStyle = { "--scale": `${scale}` } as React.CSSProperties;
  return (
    <div
      className={`NavigationBubble ${customClass} ${
        isDisabled ? "disabled" : ""
      }`}
      style={scaleStyle}
      onClick={() => {
        if (!isDisabled) handleClick();
      }}
    >
      <div>{svg}</div>
      <p>{title}</p>
    </div>
  );
};

export default NavigationBubble;
