import React from "react";
import { VisitorsSourceInterface } from "../../../types";

interface Props {
  visitorsSources: VisitorsSourceInterface[];
}

const VisitorsSourceChartBars: React.FC<Props> = ({ visitorsSources }) => {
  const sortedArray = visitorsSources.sort((a, b) => b.value - a.value);
  const maxValue = sortedArray[0].value;

  return (
    <div>
      {sortedArray.map((elem) => (
        <ChartBar
          key={elem.wilaya}
          wilaya={elem.wilaya}
          value={elem.value}
          maxValue={maxValue}
        />
      ))}
    </div>
  );
};

interface ChartBarProps {
  wilaya: string;
  value: number;
  maxValue: number;
}

const ChartBar: React.FC<ChartBarProps> = ({ wilaya, value, maxValue }) => {
  const barWidth = Math.floor((value * 100) / maxValue);
  const style = { "--bar-width": `${barWidth}%` } as React.CSSProperties;
  return (
    <div className="ChartBar" style={style}>
      <div className="texts">
        <span>{wilaya}</span>
        <span>{value}</span>
      </div>
      <div className="bar"></div>
    </div>
  );
};

export default VisitorsSourceChartBars;
