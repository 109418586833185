import React, { Component } from "react";
import { connect } from "react-redux";
import { productActions, alertActions } from "../../../actions";
import TabShow from "../_Common/TabShow";
import GroupeForm from "../../_Common/GroupeForm";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import Loader from "../../_Common/Loader";
import { Event } from "../_Common";
class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: Translate("product", "addnewproduct"),
      product_name: "",
      product_id: "",
      submited: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { idprod } = params;
    if (idprod) this.props.GetAllProduct();
  }
  componentWillReceiveProps(nextProps) {
    const { ProductAdded } = nextProps;
    const {
      match: { params },
    } = nextProps;
    if (this.props.ProductAdded === "1" && ProductAdded === "2") {
      this.props.GetAllProduct();
      this.props.history.push("/stock/products");
    }
    if (this.props.ProductAdded === "1" && ProductAdded === "3") {
      this.props.SendAlert("50", Translate("alert", "failaddpro"));
    }
    var idprod = params.idprod;
    const { ProductFinded, data } = nextProps;
    if (
      this.props.ProductFinded !== "2" &&
      ProductFinded === "2" &&
      data &&
      data.list &&
      Array.isArray(data.list)
    ) {
      let product = data.list.find(
        (product) => product.id + "" === idprod + ""
      );
      if (product) {
        this.setState({
          product_name: product.logistical_description,
          product_id: product.product_id,
          pageTitle: Translate("product", "updateproduct"),
        });
      } else {
        this.props.history.push("/stock/products");
      }
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    e.preventDefault();
    let product = {
      product_name: this.state.product_name,
      product_id: this.state.product_id,
    };
    this.setState({
      submited: true,
    });
    Event("ADD_PRODUCT", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    this.props.AddProduct(product);
  }
  render() {
    const { ProductAdded } = this.props;
    return (
      <TabShow tab_title={this.state.pageTitle}>
        <div className="MrAot" style={{ width: "88%" }}>
          <form method="post" onSubmit={this.handleSubmit}>
            <GroupeForm
              id={"productname"}
              name={"product_name"}
              value={this.state.product_name}
              placeholder={Translate("product", "productname")}
              text={Translate("product", "productname")}
              type={"text"}
              workfun={this.handleChange}
              stclass={"ClSidTh"}
            />
            <GroupeForm
              id={"productid"}
              name={"product_id"}
              value={this.state.product_id}
              placeholder={Translate("product", "productid")}
              text={Translate("product", "productid")}
              type={"text"}
              workfun={this.handleChange}
              stclass={"ClSidTh"}
            />

            <div className="Hlwd MrAot StMarMx StMrtp">
              <Button BtnText={Translate("product", "addprodct")} />
            </div>
          </form>
        </div>
        {ProductAdded === "1" && <Loader />}
      </TabShow>
    );
  }
}
function mapState(state) {
  const { ProductAdded, dataProduct, ProductFinded, data } = state.product;
  return { ProductAdded, dataProduct, ProductFinded, data };
}

const actionCreators = {
  AddProduct: productActions.AddProduct,
  GetAllProduct: productActions.GetAllProduct,
  SendAlert: alertActions.SendAlert,
};

const connectedAddProduct = connect(mapState, actionCreators)(AddProduct);
export { connectedAddProduct as AddProduct };

/*<div className="FlWd InFlx StMrtp">
    <div className="Hlwd Mrg2">
        <GroupeForm 
            id={'size'}
            name={'size'}
            placeholder={Translate("product","size")} 
            text={Translate("product","size")}   
            type={'text'}
            workfun={this.handleChange}
        />
    </div>
    <div className="Hlwd Mrg1">
        <GroupeForm 
            id={'color'}
            name={'color'}
            placeholder={Translate("product","color")} 
            text={Translate("product","color")}   
            type={'text'}
            workfun={this.handleChange}
        />
    </div>
</div>*/
