import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { configureUrlQuery } from "react-url-query";
import { history } from "../utils/history";
import IndexAuth from "../components/AuthPages/index.auth";
import { IndexStore } from "../components/StorePages/index.store";
import PrivateRoute from "./privateRoute";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
import { ArDesign, EnDesign } from "./ConstDesign";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Event, initGAPg } from "../components/StorePages/_Common";

configureUrlQuery({ history });
const lang = Cookies.get("lang");
class App extends Component {
  constructor(props) {
    super(props);

    if (process.env.REACT_APP_STATE === "prod") {
      // Init Sentry events listening
      Sentry.init({
        dsn: "https://dda5a807958940899f415c2835b7c17d@o755902.ingest.sentry.io/5809901",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

    if (window.matchMedia("(display-mode: standalone)").matches) {
      Event("PWA", "CONSULT_FROM_PWA", "VISIT_EVENT");
    }
  }
  render() {
    let style;
    if (lang === "ar") {
      style = ArDesign;
    } else {
      style = EnDesign;
    }
    if (Cookies.get("lang") === "ar") {
      document.dir = "rtl";
    }
    return (
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Helmet>{style}</Helmet>
          <PrivateRoute private={IndexStore} public={IndexAuth} />
        </QueryParamProvider>
      </Router>
    );
  }
}
export default connect()(App);

// Initialize google Analytics
initGAPg();
// Listen when Add to Home Screen prompted
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  // Stash the event so it can be triggered later.
  let deferredPrompt = e;
  deferredPrompt.prompt();
  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      Event("ADD_TO_HOME_SCREEN", "ACCEPT_INSTALL", "CLICK_EVENT");
      // alert('User accepted the A2HS prompt');
    } else {
      Event("ADD_TO_HOME_SCREEN", "DISMISS_INSTALL", "CLICK_EVENT");
      // alert('User dismissed the A2HS prompt');
    }
    deferredPrompt = null;
  });
});
