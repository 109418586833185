import { dashboardConstants } from '../constants';
export function dashboard(state , action) {
    switch (action.type) {
        case dashboardConstants.GET_STATUS_STORE_REQUEST:
            return {
                ...state,
                GetingStat:"0",
                Statdata: action.payload
            };
        case dashboardConstants.GET_STATIC_STATUS_STORE_REQUEST:
            return {
                ...state,
                GetingGlobalStat:"0",
                GlobalStats: action.payload
            };
        case dashboardConstants.GET_TODAY_STATUS_STORE_REQUEST:
            return {
                ...state,
                GettingTodayStats:"0",
                TodayStats: action.payload
            };
        case dashboardConstants.GET_STATUS_STORE_SUCCESS:
            return {
                ...state,
                GetingStat:"1",
                Statdata: action.payload
            };
        case dashboardConstants.GET_STATIC_STATUS_STORE_SUCCESS:
            return {
                ...state,
                GetingGlobalStat:"1",
                GlobalStats: action.payload
            };
        case dashboardConstants.GET_TODAY_STATUS_STORE_SUCCESS:
            return {
                ...state,
                GettingTodayStats:"1",
                TodayStats: action.payload
            };
        case dashboardConstants.GET_STATUS_STORE_FAILURE:
            return {
                ...state,
                GetingStat:"2",
                Statdata: action.payload
            };
        case dashboardConstants.GET_STATIC_STATUS_STORE_FAILURE:
            return {
                ...state,
                GetingGlobalStat:"2",
                GlobalStats: action.payload
            };
        case dashboardConstants.GET_TODAY_STATUS_STORE_FAILURE:
            return {
                ...state,
                GettingTodayStats:"2",
                TodayStats: action.payload
            };

        case dashboardConstants.GET_TOP_PRODUCT_REQUEST:
            return {
                ...state,
                GetingTopProd:"0",
                TopProdData: action.payload
            };
        case dashboardConstants.GET_TOP_PRODUCT_SUCCESS:
            return {
                ...state,
                GetingTopProd:"1",
                TopProdData: action.payload
            };
        case dashboardConstants.GET_TOP_PRODUCT_FAILURE:
            return {
                ...state,
                GetingTopProd:"2",
                TopProdData: action.payload
            };
        case "Clear_Dashboard":
            return {}
        default:
            return {...state}
        }
}