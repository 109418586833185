import React from "react";
import PropTypes from "prop-types";
import InlinedElement from "../../_Common/InlinedElement";
import { FlechDwnSvg } from "../../_Common/IconSvg";

// const FilterElement=React.forwardRef((props,ref)=> {
class FilterElement extends React.Component {

  render(){
    const {props}=this

    return (
      <div ref={props.ref} className="BdgClN StBrdRdS StPdsml SmMarS1">
        <div className="StHeivl RlPs CrsPoi" onClick={props.handler}>
          <InlinedElement
            secnd={<strong>{props.title}</strong>}
            leftElm={<div className="InFlx">{FlechDwnSvg}</div>}
            style={{ width: "100%", padding: "0px" }}
          />
        </div>
        <div
          id={props.container}
          className="hidElem3 stTranEs"
          style={{ overflowY: "scroll" }}
        >
          <div>
              {props.children}
          </div>
        </div>
      </div>
    );
  }
}

FilterElement.propTypes = {
  ref: PropTypes.object.isRequired,
  container: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
};

export default FilterElement;
