import React from "react";
import { Statistic } from "../../../types";
import { Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import StatisticsDisplayer from "./StatisticsDisplayer";

interface Props {
  salesPerformance: Statistic[];
}

const SalesPerformance: React.FC<Props> = ({ salesPerformance }) => {
  return (
    <div className="SalesPerformance">
      <ContainerPage
        page_title={Translate("titles", "salesPerformance")}
        small_title
        lineHeader
        subContainer
      >
        <StatisticsDisplayer statistics={salesPerformance} />
      </ContainerPage>
    </div>
  );
};

export default SalesPerformance;
