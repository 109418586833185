import React from 'react'
import PropTypes from 'prop-types'
import ElementSide from './ElementSide'

function SideMenu(props) {
    return (
        <div className="FlHg StVlWdt ClSidTh StBxSh stbd2 OvfAtoY StBgbrd  HdOvFlwX StMerV StPs StTpNl">
                <div rol="sub_side">
                    {Array.isArray(props.items) && props.items.map(item=>(
                        <ElementSide 
                        icon={item.icon} 
                        to={item.to} 
                        active={props.pathname===item.to} 
                        text={item.text}/>

                    )) 
                    
}
                </div>    
            </div>
    )
}

SideMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.object.isRequired,
            to: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    pathname: PropTypes.string.isRequired,

}

export default SideMenu

