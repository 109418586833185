import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
const requiredInputs = [
  {
    id: "full_name",
    name: "full_name",
    placeholder: Translate("auths", "fullname"),
    text: Translate("auths", "fullname"),
    type: "text",
  },
  {
    id: "phone_number",
    name: "phone",
    placeholder: Translate("auths", "phone"),
    text: Translate("auths", "phone"),
    type: "tel",
    pattern: "^((+216)?[1-9]d{7}|(0?|(00|+)?213)[56789]d{8})$",
  },
  {
    id: "Username",
    name: "user_name",
    placeholder: Translate("auths", "username"),
    text: Translate("auths", "username"),
    type: "text",
    pattern: "^\\S+",
  },
  {
    id: "Email",
    name: "email",
    placeholder: Translate("auths", "email"),
    text: Translate("auths", "email"),
    type: "email",
  },
  {
    id: "Password",
    name: "password",
    placeholder: Translate("auths", "password"),
    text: Translate("auths", "password"),
    type: "password",
  },
  {
    id: "confirmed_password",
    name: "confirmed_password",
    placeholder: Translate("auths", "confpassword"),
    text: Translate("auths", "confpassword"),
    type: "password",
  },
];
class RegisterPage extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      full_name: user ? user.full_name : "",
      phone: user ? user.phone : "",
      user_name: user ? user.user_name : "",
      email: user ? user.email : "",
      password: "",
      confirmed_password: "",
      terms_use: "",
      privacy_police: "",
      submited: false,
      shows: requiredInputs,
      errors: Object.assign(
        {},
        ...Array.from(requiredInputs, (el) => ({ [el.name]: "" }))
      ),
      alreadyExists: {},
      code: "+213",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleAlreadyHaveAccount = this.handleAlreadyHaveAccount.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "register");
    initGAPg();
  }

  onChangeCode(e) {
    this.setState({ code: e.target.value });
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, alreadyExists: {} });
  }
  handleChangeCheck(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  }
  handleAlreadyHaveAccount(e) {
    Event("REGISTER", "ALREADY_HAVE_ACCOUNT", "CLICK_EVENT");
  }
  handleSubmit(e) {
    e.preventDefault();
    const { password, confirmed_password, terms_use, privacy_police } =
      this.state;
    let valid = true,
      err = {};
    Event("REGISTER", "SUBMIT_ACCOUNT_INFOS", "CLICK_EVENT");
    requiredInputs.forEach((el) => {
      if (!this.state[el.name]) {
        err[el.name] = Translate("error", "reqfield");
        valid = false;
      }
    });
    if (password && password.length < 6) {
      err.password = Translate("error", "e19");
      valid = false;
    }
    if (password !== confirmed_password) {
      err.confirmed_password = Translate("error", "e20");
      valid = false;
    }
    if (!terms_use) {
      err.terms_use = true;
      valid = false;
    }
    if (!privacy_police) {
      err.privacy_police = true;
      valid = false;
    }
    this.setState({ errors: err });
    if (!valid) {
      Event(
        "REGISTER",
        "ACCOUNT_FORM_ERRORS",
        `[${Object.keys(err).join(",")}]`
      );
      return;
    } else {
      const { full_name, user_name, email, password, confirmed_password } =
        this.state;
      const { phone } = this;
      const PremierChiffre = this.state.phone.substr(0, 1);
      if (PremierChiffre === "0") {
        const elem = this.state.phone.slice(1);
        const phone = this.state.code + elem;
        this.props.RegisterTap({
          full_name,
          phone,
          user_name,
          email,
          password,
          confirmed_password,
        });
      } else {
        const elem = this.state.phone;
        const phone = this.state.code + elem;
        this.props.RegisterTap({
          full_name,
          phone,
          user_name,
          email,
          password,
          confirmed_password,
        });
      }

      // Save this step infos temporary in case of abondon/lost connexion in next step
      Cookies.set(
        "temp-user-infos",
        { full_name, phone, user_name, email, password, confirmed_password },
        { expires: "" }
      );
    }
  }
  render() {
    const { Sended } = this.props;
    if (Sended === "0") {
      // return <Redirect to="/store/add"/>
      return <Redirect to="/register/continue" />;
    }
    const errData = this.props.data?.response?.data;
    if (
      (this.props.CrtingMember === "2" || this.props.registered === "2") &&
      errData
    ) {
      const { alreadyExists } = this.state;
      if (errData.phone && !alreadyExists.phone)
        alreadyExists.phone = Translate("error", "phoneexists");
      if (errData.phone && !alreadyExists.phone)
        alreadyExists.phone = Translate("error", "phoneexists");

      this.setState({ alreadyExists });
    }
    return (
      <ContainerLog>
        <HeadLog
          titleHead={Translate("auths", "createaccount")}
          descHead={Translate("auths", "signuptext")}
        />
        <form method="post" onSubmit={this.handleSubmit} className="StMarMx">
          {this.state.shows.map((group, index) => {
            const { id, name, placeholder, text, type, ...others } = group;
            return (
              <GroupeForm
                key={index}
                id={id}
                name={name}
                placeholder={placeholder}
                text={text}
                type={type}
                value={this.state[name]}
                {...others}
                workfun={this.handleChange}
                error={
                  this.state.errors[name] || this.state.alreadyExists[name]
                }
                select={
                  <select
                    defaultValue={this.state.code}
                    onChange={this.onChangeCode}
                    className="selectPhone"
                  >
                    <option value="+213">DZ +213</option>
                    <option value="+216">TN +216</option>
                  </select>
                }
              />
            );
          })}
          <CheckBoxForm
            id={"terms_use"}
            name={"terms_use"}
            text={
              <div>
                {Translate("auths", "ihaveread")}
                <Link className="StBle stbold StWdDst" to="/privace">
                  {Translate("auths", "termus")}
                </Link>
              </div>
            }
            workfun={this.handleChangeCheck}
            error={this.state.errors.terms_use}
          />
          <CheckBoxForm
            id={"privacy_police"}
            name={"privacy_police"}
            text={
              <div>
                {Translate("auths", "ihaveread")}
                <Link className="StBle stbold StWdDst" to="/police">
                  {Translate("auths", "privacypolic")}
                </Link>
              </div>
            }
            workfun={this.handleChangeCheck}
            error={this.state.errors.privacy_police}
          />
          <div className="SmtGrp MrAot StMarMx">
            <Button BtnText={Translate("auths", "signup")} />
          </div>
        </form>
        <div className="StOpcVal">
          <div className="StMrg">
            <span className="StWdDst">{Translate("auths", "haveaccount")}</span>
            <Link
              className="StBle stbold StWdDst"
              to="/login"
              onClick={this.handleAlreadyHaveAccount}
            >
              {Translate("auths", "logins")}
            </Link>
          </div>
        </div>
      </ContainerLog>
    );
  }
}
function mapState(state) {
  const { Sended, user, data, CrtingMember, registered } = state.registration;
  return { Sended, user, data, CrtingMember, registered };
}

const actionCreators = {
  RegisterTap: userActions.RegisterTap,
};

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
