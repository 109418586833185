import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../../utils/lang/translate";
import TabTeam from "./TabTeam";
import { userActions } from "../../../../actions";
import { TeamLoader } from "./TeamLoader";
import { Link } from "react-router-dom";
import { Event, initGAPg } from "../../_Common";
class TeamIndex extends Component {
  constructor(props) {
    super(props);
    if (this.props.GetingTeam !== "1") {
      this.props.ListTeam();
    }
    this.handleAddTeam = this.handleAddTeam.bind(this);
  }
  componentDidMount() {
    initGAPg();
  }
  handleAddTeam(e) {
    Event("TEAMS", "USE_ADD_NEW_TEAM_BUTTON", "CLICK_EVENT");
  }
  render() {
    const { GetingTeam, dataListTeam } = this.props;
    return (
      <div>
        <div className="stbd2">
          <h3 className="DlMg">{Translate("settings", "storeteamor")}</h3>
        </div>
        {GetingTeam === "0" ? (
          <div className="InFlx FlWd StFlxWrp2">
            <TeamLoader /> <TeamLoader />
          </div>
        ) : (
          <div className="InFlx FlWd StFlxWrp2 responsive-team">
            {GetingTeam === "1" &&
              dataListTeam.teams.map((elem, key) => {
                return (
                  <TabTeam
                    key={key}
                    path={"/settings/team/view/" + elem.id}
                    name={elem.name}
                    permissions={elem.permissions}
                    vOrd={elem.view_order}
                    vsOrd={elem.view_stock}
                    vpOrd={elem.view_payment}
                    aeOrd={elem.add_edit_order}
                    lstmem={[null]}
                  />
                );
              })}
            <div className=" StWdpwrp StBrdRd SthgMaxC">
              <Link to="/settings/team/add/new" onClick={this.handleAddTeam}>
                <div
                  className="BdgBlcl StBrdRd"
                  style={{ padding: "10px 20px" }}
                >
                  <div className="FlWd RlPs">
                    <span>{Translate("settings", "addteam")}</span>
                    <span className="StLanNl StAbs">+</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapState(state) {
  const { GetingTeam, dataListTeam } = state.team;
  return { GetingTeam, dataListTeam };
}

const actionCreators = {
  ListTeam: userActions.ListTeam,
};

const connectedTeamIndex = connect(mapState, actionCreators)(TeamIndex);
export { connectedTeamIndex as TeamIndex };
