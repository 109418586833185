import React,{ Component } from 'react'
import { connect } from 'react-redux'
import {userActions} from '../../../actions'
import {Translate} from '../../../utils/lang/translate'
import {Redirect} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Event, initGAPg } from '../../StorePages/_Common'

class CompleteRegister  extends Component{
    constructor(props) {
        super(props);
        this.goToStoreRegistration = this.goToStoreRegistration.bind(this);
        this.goToJoinStore = this.goToJoinStore.bind(this);
    }
    componentDidMount() {
        document.title = 'Maystro Delivrey - '+Translate("titles","register");
        initGAPg()

    }
    goToStoreRegistration(e){
        Event("REGISTER","CHOOSE_CREATE_STORE_CARD","CLICK_EVENT")
        this.props.history.push("/store/add")

    }
    goToJoinStore(e){
        Event("REGISTER","CHOOSE_CREATE_STORE_CARD","CLICK_EVENT")
        this.props.history.push("/store/join")

    }
   
    
    render(){
       const {user}=this.props;
       if(!user || !Cookies.get("temp-user-infos")) {
           return <Redirect to="/register" />

       }
        return(
            <div className="CnGl DlPg DlMg FxPs FlWd FlHg OvfAtoY">
                <div className="FlHg InFlx responsFlx flxDrc spcEvn AlgnItm" >
                        <div className="InFlx Stclmnf JstfCnt AlgnItm TxtCn ClSidTh CrsPoi" style={{width: "30%",borderRadius: "24px", padding: "3% 5%"}}
                        onClick={this.goToStoreRegistration}
                        >
                            <img src="/assets/img/store_owner.png" alt="Maystro Delivery logo" style={{width: "40%",maxWidth: "70vw"}}/>
                            <h4>{Translate("auths","amstoreowner")}</h4>
                            <p>{Translate("auths","amstoreownerdesc")}</p>

                        </div>
                        
                    

                        <div className="InFlx Stclmnf JstfCnt AlgnItm TxtCn ClSidTh CrsPoi" style={{width: "30%",borderRadius: "24px", padding: "3% 5%"}}
                        onClick={this.goToJoinStore}
                        >
                            <img src="/assets/img/join_team.png" alt="Maystro Delivery logo" style={{width: "40%",maxWidth: "70vw"}}/>
                            <h4>{Translate("auths","aminvited")}</h4>
                            <p>{Translate("auths","aminviteddesc")}</p>

                        </div>

                        

                </div>
            </div>
            )
    }
}
function mapState(state) {
    const {Sended,user}=state.registration 
    return {Sended,user}
}

const actionCreators = {
    RegisterTap: userActions.RegisterTap
};

const connectedCompleteRegister = connect(mapState, actionCreators)(CompleteRegister);
export { connectedCompleteRegister as CompleteRegister };