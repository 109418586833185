import React from "react";
import { Statistic } from "../../../types";
import {
  convertMoneyIntoString,
  convertTimeIntoString,
} from "../../../utils/helpers";
import { Translate } from "../../../utils/lang/translate";

interface Props {
  statistics: Statistic[];
}

const StatisticsDisplayer: React.FC<Props> = ({ statistics }) => {
  const handleStatisticValue: (value: number, type: string) => string = (
    value,
    type
  ) => {
    switch (type) {
      case "conversionRate":
        return `${value}%`;
      case "TimeOnPage":
        return convertTimeIntoString(value);
      case "unrealizedRevenue":
        return convertMoneyIntoString(value, "Da");
      default:
        return value.toString();
    }
  };

  const convertRateIntoJSX: (rate: number) => React.ReactElement = (rate) =>
    rate >= 0 ? (
      <span className="green">+{rate}%</span>
    ) : (
      <span className="red">{rate}%</span>
    );

  return (
    <div className="StatisticsDisplayer">
      {statistics.map((stat, key) => (
        <div key={key}>
          <p className="title">{Translate("shop", stat.name)}</p>
          <h3 className="value">
            {handleStatisticValue(stat.value, stat.name)}
          </h3>
          <p className="rate">
            {convertRateIntoJSX(stat.rate)}{" "}
            {Translate("shop", "fromPreviousPeriod")}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StatisticsDisplayer;
