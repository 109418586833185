import React from "react";
export default function ListDetailIcon(props) {
  return (
    <div className="InFlx flxDrc FlWd RlPs">
      <div
        className={
          "StMrtg2 StHgVls " + (props.Styleicon ? props.Styleicon : "StBlFil")
        }
      >
        {props.icon}
      </div>
      <div>
        <div className="StOpcVal">{props.title}</div>
        {props.details.map((elem, key) => {
          return (
            <div className="StSizLn stbold TxTrnf StpdVlrglt" key={key}>
              {elem}
            </div>
          );
        })}
      </div>
    </div>
  );
}
