import React,{Component} from 'react'
import { connect } from 'react-redux'
import ContainerPage from '../_Common/ContainerPage'
import {Translate} from '../../../utils/lang/translate'
import {UnderDevPage} from '../_Common/UnderDevPage'
class IssuesPage extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
   
    componentDidMount() {
        document.title = 'Maystro Delivery - '+Translate("titles","issues");
    }
    render(){
        return(
            <ContainerPage page_title={Translate("titles","issues")}>
                <UnderDevPage/>
            </ContainerPage>
        )
    }
    
}
function mapState(state) {
     
}

const actionCreators = {
    
};

const connectedIssuesPage = connect(mapState, actionCreators)(IssuesPage);
export { connectedIssuesPage as IssuesPage };