import React, { useState, Fragment, useEffect } from "react";
import { Translate } from "../../../utils/lang/translate";
import { FormatDate } from "../../../utils/lang/translate";
import { Link, useParams } from "react-router-dom";
import { PrintSvg } from "../_Common/IconSvg";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import PaginationBar from "../_Common/PaginationBar";
import { DETAIL_PRODUCT_STARTER, DETAIL_PRODUCT_PREMIUM } from "../../constants";
import SmallText from "../_Common/SmallText";
import { initGAPg, Event } from "../_Common";

import { commonActions, productActions, userActions } from "../../../actions";
import PrintsExit from "./generatePDFexit";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import Cookies from "js-cookie";

const theme = Cookies.get("theme");

const DetailsExit = ({ match, GetDetailsTakenProducts, GetAllexitsProducts, dataDetailsVoucherTaken, dataExit, GetInfoMaystro, dtStoreinfo, dataMaystro }) => {
  const {
    params: { id },
  } = match;
  const [urlImg, setUrlImg] = useState("");
  const [ImgHead, setImgHead] = useState("");
  const [data, setData] = useState();

  useEffect(() => {
    initGAPg();
  });
  useEffect(() => {
    if (dataExit?.results) {
    }
    setData(dataExit?.results?.find((prod) => prod.id === id));
  }, [dataExit?.results]);

  useEffect(() => {
    GetDetailsTakenProducts(id);
  }, [id]);

  useEffect(() => {
    GetAllexitsProducts();
  }, []);
  useEffect(() => {
    GetInfoMaystro(dtStoreinfo?.country?.id);
  }, []);

  let bodytab = [];

  if (dataDetailsVoucherTaken?.type === 2) {
    bodytab = dataDetailsVoucherTaken?.details?.map((elem) => {
      const { order_name, order_id } = elem;

      return ["#" + order_id, <SmallText lengthText={70}>{order_name}</SmallText>];
    });
  } else if (dataDetailsVoucherTaken?.type === 1) {
    bodytab = dataDetailsVoucherTaken?.details?.map((elem) => {
      const { product, quantity } = elem;

      return [<span>{product}</span>, <span>{quantity}</span>];
    });
  }

  const getDataUrl = (img) => {
    // Create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
  };

  const print = (e) => {
    Event("VOUCHER_TAKEN_DETAILS", "PRINT_VOUCHER_TAKEN", "CLICK_EVENT");
    e.preventDefault();
    var img = new Image();
    img.src = "/assets/img/maystro-blue.png";
    img.addEventListener("load", function (event) {
      let dtulr = getDataUrl(event.currentTarget);
      setUrlImg(dtulr);
      setImgHead(getDataUrl(event.currentTarget));
    });

    const pdf = new jsPDF();
    var col = ["ID", "commande"];
    var col1 = ["Produit", "Quantité"];
    var rows = [];
    var rows1 = [];

    const starter = dataDetailsVoucherTaken?.details.forEach((element) => {
      var str = [element.order_id, element.order_name];
      rows.push(str);
    });

    const premium = dataDetailsVoucherTaken?.details.forEach((element) => {
      var prm = [element.product, element.quantity];
      rows1.push(prm);
    });

    pdf.addImage(img, "png", 80, 2, 50, 30);
    pdf.setFontSize(25);
    pdf.setFont(undefined, "bold");

    pdf.text(`bon de sortie N° BS/${dataDetailsVoucherTaken.reference}`, 100, 43, "center");
    pdf.setFontSize(13);
    pdf.setFont(undefined, "normal");

    pdf.text(`Date: ${FormatDate(dataDetailsVoucherTaken.created_at)}`, 100, 50, "center");
    pdf.text(18, 70, "SARL Maystro Services");
    pdf.text(18, 75, dataMaystro?.address);
    pdf.text(18, 85, dataMaystro?.Email);
    pdf.text(18, 90, `Tel : ${dataMaystro?.["Phone number"]}`);
    pdf.text(`Store : ${dataDetailsVoucherTaken.store}`, 200, 100, "right");
    pdf.text(`${dataDetailsVoucherTaken.store_phone}`, 200, 105, "right");

    if (dataDetailsVoucherTaken?.type === 2) {
      pdf.setFontSize(18);
      pdf.setFont(undefined, "bold");

      pdf.text(18, 130, "Liste des commandes");
    } else {
      pdf.setFontSize(18);
      pdf.setFont(undefined, "bold");

      pdf.text(18, 130, "Liste des produits");
    }

    if (dataDetailsVoucherTaken?.type === 2) {
      pdf.autoTable(col, rows, { startY: 140 });
    } else {
      pdf.autoTable(col1, rows1, { startY: 140 });
    }

    pdf.save(`Bon_N° BS/${dataDetailsVoucherTaken.reference}.pdf`);
  };

  return (
    <ContainerPage
      page_title={
        <div>
          <Link className="StOpcVal" to="/stock/takenProduct">
            {Translate("titles", "receiveProduct")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="StOpcVal">{Translate("product", "takenProduct")}</span>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span>
            {Translate("product", "titleexit")} N° BS/
            {dataDetailsVoucherTaken?.reference}
          </span>
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc   ">
          <div className="d-large">
            <h3 className="StdirLn">{Translate("product", "exitdetails")}</h3>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "40px",
              }}
            >
              <h4 className={theme == 0 ? "titledetails2Light" : "titledetails2"}>{Translate("product", "exitfrom")}</h4>
              <h4 className={theme == 0 ? "titledetailsLight" : "titledetails"}>{Translate("product", "exitto")}</h4>
              <h4 className={theme == 0 ? "titledetails3Light" : "titledetails3"}>{Translate("product", "exitdate")}</h4>
            </div>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "22px",
              }}
            >
              <h4 className={theme == 0 ? "titledetails2Light" : "titledetails2"}>SARL Maystro Services</h4>
              <h4 className={theme == 0 ? "titledetailsLight" : "titledetails"}>{dataDetailsVoucherTaken?.store}</h4>
              <h4 className="detailsgris">{FormatDate(dataDetailsVoucherTaken?.created_at)}</h4>
            </div>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "22px",
              }}
            >
              <h4 className="detailsgris2">{dataMaystro?.address}</h4>
              <h4 className="detailsgris3">{dataDetailsVoucherTaken?.store_phone}</h4>
              <h4 className="detailsgris"></h4>
            </div>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "22px",
              }}
            >
              <h4 className="detailsgris3"></h4>
              <h4 className="detailsgris"></h4>
            </div>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "22px",
              }}
            >
              <h4 className="detailsgris2">{dataMaystro?.Email}</h4>
              <h4 className="detailsgris3"></h4>
              <h4 className="detailsgris"></h4>
            </div>
            <div
              style={{
                paddingRight: "89px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h4 className="detailsgris2">{dataMaystro?.["Phone number"]}</h4>
              <h4 className="detailsgris3"></h4>
              <h4 className="detailsgris"></h4>
            </div>
          </div>

          {/* responsive mobile */}
          <div className="d-sm">
            <h3 className="StdirLn">{Translate("product", "exitdetails")}</h3>
            <div>
              <h4 className={theme == 0 ? "titledetails2Light" : "titledetails2"}>
                <h3 className="StdirLn">{Translate("product", "exitfrom")}</h3>
              </h4>
              <h4 className="detailsgris2">SARL Maystro Services</h4>
              <h4 className="detailsgris2">{dataMaystro?.address}</h4>

              <h4 className="detailsgris2">{dataMaystro?.Email}</h4>
              <h4 className="detailsgris2">{dataMaystro?.["Phone number"]}</h4>
            </div>

            <div>
              <h4 className={theme == 0 ? "titledetailsLight" : "titledetails"}>
                <h3 className="StdirLn">{Translate("product", "exitto")}</h3>
              </h4>
              <h4 className={theme == 0 ? "titledetailsLight" : "titledetails"}>{dataDetailsVoucherTaken?.store}</h4>
              <h4 className="detailsgris3">{dataDetailsVoucherTaken?.store_phone}</h4>
            </div>
            <div>
              <h4 className={theme == 0 ? "titledetails3Light" : "titledetails3"}>
                <h3 className="StdirLn">{Translate("product", "exitdate")}</h3>
              </h4>
              <h4 className="detailsgris">{FormatDate(dataDetailsVoucherTaken?.created_at)}</h4>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3 className="StdirLn">{Translate("product", "listProd")}</h3>
            <Fragment>
              <Table thead_elem={dataDetailsVoucherTaken?.type === 2 ? [...DETAIL_PRODUCT_STARTER] : [...DETAIL_PRODUCT_PREMIUM]} tbody_elem={bodytab} />
            </Fragment>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div onClick={print} className="buttonPrint">
              <div style={{ marginTop: "5px", marginRight: "5px" }}>{PrintSvg}</div>
              <span>Print</span>
            </div>
          </div>
        </div>
      </div>
    </ContainerPage>
  );
};

function mapState(state) {
  const { GettingDetailsVoucherTaken, dataDetailsVoucherTaken, dataExit } = state.product;
  const { gtStoreInf, dtStoreinfo } = state.user;

  const { GetingInfomaystro, dataMaystro } = state.common;
  return {
    GettingDetailsVoucherTaken,
    dataDetailsVoucherTaken,
    dataExit,
    gtStoreInf,
    dtStoreinfo,

    GetingInfomaystro,
    dataMaystro,
  };
}

const actionCreators = {
  GetDetailsTakenProducts: productActions.GetDetailsTakenProducts,
  GetAllexitsProducts: productActions.GetAllexitsProducts,
  GetInfoMaystro: commonActions.GetInfoMaystro,
};

const connectedVouchourTaken = connect(mapState, actionCreators)(DetailsExit);

export { connectedVouchourTaken as DetailsExit };
