import React from "react"
import ContentLoader from "react-content-loader"
const LoaderStore = (props) => (
    <ContentLoader 
        speed={2}
        height={500}
        width={700}
        viewBox="0 0 700 500"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
    <circle cx="360" cy="45" r="40" />
    <rect x="0" y="90" rx="0" ry="0" width="700" height="40" />
    <rect x="0" y="140" rx="0" ry="0" width="700" height="40" />
  </ContentLoader>
  )
  
  export default LoaderStore