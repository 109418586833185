import { exchangeConstants } from '../constants';

export function exchanges(state , action) {
  switch (action.type) {
    case exchangeConstants.ADD_EXCHANGE_REQUEST:
      return {
        ExchangeAdded:"1",
        dataAddExchange: action.payload
      };
    case exchangeConstants.ADD_EXCHANGE_SUCCESS:
      return {
        ExchangeAdded:"2",
        dataAddExchange: action.payload
      };
    case exchangeConstants.ADD_EXCHANGE_FAILURE:
      return {
        ExchangeAdded:"3",
        dataAddExchange: action.payload
      };
    case exchangeConstants.ADD_REFUND_REQUEST:
      return {
        RefundAdded:"1",
        dataAddRefund: action.payload
      };
    case exchangeConstants.ADD_REFUND_SUCCESS:
      return {
        RefundAdded:"2",
        dataAddRefund: action.payload
      };
    case exchangeConstants.ADD_REFUND_FAILURE:
      return {
        RefundAdded:"3",
        dataAddRefund: action.payload
      };
    
    default:
        return {...state}
    }
}
