import React, { useState } from "react";
import PropTypes from "prop-types";
import InlinedElement from "./InlinedElement";

function DropDown(props) {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  const { list, headerTitle } = props;
  const toggleList = (e) => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="InFlx Stclmnf">
      <div className="dd-header" onClick={toggleList} style={{width: "max-content",alignSelf:"flex-end"}}>
        <div className="dd-header-title">
            {headerTitle}
        </div>
      </div>
      {isOpen && (
        <div role="list" className="ClSidTh StBgbrds stTranEs StBxSh1">
          {list.map((item) => (
              <div
              className="CrsPoi InFlx AlgnItm"
              style={{ minHeight: "50px" }}
              onClick={item.handler}
              >
                <InlinedElement 
                first={item.icon}
                secnd={item.label}
                />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

DropDown.propTypes = {

    isOpen: PropTypes.bool.isRequired,
    headerTitle: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
};

export default DropDown;
