import React, { useEffect } from "react";
import { commonActions, userActions } from "../../../actions";
import { officeConstants } from "../../../constants";
import { Translate } from "../../../utils/lang/translate";
import { ProductsPage } from "../ProductsPage";
import ContainerPage from "../_Common/ContainerPage";
import { CallSvg, EmailSvg, LocationSvg } from "../_Common/IconSvg";

import { connect } from "react-redux";

function ContactUs(props) {
  useEffect(() => {
    props.GetInfoMaystro(props?.dtStoreinfo?.country?.id);
  }, []);

  return (
    <ContainerPage page_title={Translate("footer", "contactus")} withOvrl>
      <h3 className="TxtCn">{Translate("footer", "getintouch")}</h3>

      <div className="FlWd InFlx JstfCnt">
        <div style={{ marginBlockStart: "5vh" }}>
          <div className="InFlx flxDrc" style={{ marginBlockEnd: "3vh" }}>
            {LocationSvg} {props?.dataMaystro?.address}
          </div>
          <div className="InFlx flxDrc" style={{ marginBlockEnd: "3vh" }}>
            {CallSvg} {props?.dataMaystro?.["Phone number"]}
          </div>
          <div className="InFlx flxDrc" style={{ marginBlockEnd: "3vh" }}>
            {EmailSvg} {props?.dataMaystro?.Email}
          </div>
        </div>
      </div>
    </ContainerPage>
  );
}

function mapState(state) {
  const { GetingInfomaystro, dataMaystro } = state.common;
  const { dtStoreinfo } = state.user;
  return { GetingInfomaystro, dataMaystro, dtStoreinfo };
}

const actionCreators = {
  GetInfoMaystro: commonActions.GetInfoMaystro,
  GetStoreInfo: userActions.GetStoreInfo,
};

const connectedContactUsPage = connect(mapState, actionCreators)(ContactUs);
export { connectedContactUsPage as ContactUs };
