import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
Chart.defaults.color = "#a9adb3";

const options: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
      labels: {
        color: "white",
      },
    },
    title: {
      display: false,
      text: "Total Visits",
      position: "bottom" as const,
      color: "white",
    },
  },
  font: {
    family: "var(--fontLan)",
  },
};

interface Props {
  labels: string[];
  data: number[];
}

const VisitorsChart: React.FC<Props> = ({ data, labels }) => {
  const datasets = [
    {
      label: "Visits",
      borderColor: "#0A87FA",
      backgroundColor: "#0A87FA",
      data,
    },
  ];

  return (
    <div className="VisitorsChart">
      <Line options={options} data={{ labels, datasets }} />
    </div>
  );
};

export default VisitorsChart;
