import React,{Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {alertActions} from '../../../actions'
import {CloseSvg,RplySvg,LoadingSvg,FileDownSvg} from '../_Common/IconSvg'
import {Translate} from '../../../utils/lang/translate'
import DetailAlert from './DetailAlert'
class AlertPage extends Component{
    constructor(props){
        super(props)
        this.state={
            actions:{
                a01:<Link to="/order/add/new"><DetailAlert dataD={[RplySvg,Translate('orders','addneworder')]}/></Link>,
                a02:Translate('alert','Fixmising'),
                a03:<Link to="/orders"><DetailAlert dataD={[RplySvg,Translate('alert','gotoorder')]}/>
                    </Link>,
                a04:<DetailAlert dataD={[LoadingSvg,Translate('alert','moveaxtion')]}/>,
                a05:<DetailAlert dataD={[FileDownSvg,Translate('alert','autodown')]}/>,
                a06:<DetailAlert dataD={[RplySvg,Translate('alert','tryagain')]}/>
            }
        }
        this.Enterval=null
        this.externalAction=true
        this.ClearAlert=this.ClearAlert.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {alertCode,alertData}=nextProps
        if(alertData && alertCode!=="31"){
            this.Enterval=setInterval(this.ClearAlert,6000);
        }
    }
    
    downloadFileOrder(data, name) {
        var blob = new Blob([data], {
            type: ''
        });
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = name;
        a.click();
    }
    ClearAlert() {
        var elem = document.getElementById("alert_nto");  
        if(elem){
            var pos = elem.style.bottom;
            let opc=100
            let bndthiq=this
            var id = setInterval(frame, 5);
            function frame() {
              if (pos === -100) {
                clearInterval(id);
                clearInterval(bndthiq.Enterval)
                bndthiq.props.SendAlert('','','','')
              } else {
                pos=pos-5;
                opc=opc-5 
                elem.style.bottom = pos + "px"; 
                elem.style.opacity=opc+"%"
              }
            }
        } else{
            clearInterval(this.Enterval)
        }
      }
    render(){
        const {alertCode,alertData}=this.props
        return(
            alertData ? <div id="alert_nto" className="StAbs StCordNt InFlx flxDrc HdOvrfl StBrdRdS" style={{zIndex:"100"}}>
                <span className={"FlHg StAbs status_"+alertCode} style={{width:"7px"}}></span>
                <div className={"StpdElm Tstatus_"+alertCode}>
                    <div className="RlPs FlHg FlWd">
                        <div className="StMrtg5 RlPs Zindxsm">
                            <div className="StdirLn">
                                <h4 className="DlMg">{alertData.txt}</h4>
                            </div>
                            <div style={{marginTop:"4px"}} className="RlPs">{this.state.actions[alertData.action]}</div>
                        </div>
                        {alertCode!=="31"? 
                        <div style={{zoom:".5"}} className="StAbs StdirLn CrsPoi FlWd FlHg StTpNl StlfNl InFlx AlgnItm StFlxSDp">
                            <div onClick={this.ClearAlert}>{CloseSvg}</div>
                        </div> :''}
                    </div>
                </div>
            </div> : ''
        )
    }
    
}
function mapState(state) {
     const {alertCode,alertData}=state.alert
     return {alertCode,alertData}
}
const actionCreators = {
    SendAlert:alertActions.SendAlert
};
const connectedAlertPage = connect(mapState,actionCreators)(AlertPage);
export { connectedAlertPage as AlertPage };