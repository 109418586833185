import React, { useState, useEffect } from "react";
import Button from "../../../_Common/Button";
import { Translate } from "../../../../utils/lang/translate";
import { DataShop, UpdateDataShopSettings } from "../../../../types";
import { Event } from "../../_Common";

const themes: { name: string; id: "light" | "dark"; picture: string }[] = [
  {
    name: "Light Theme",
    id: "light",
    picture: "theme-light.jpg",
  },
  {
    name: "Dark theme",
    id: "dark",
    picture: "theme-dark.jpg",
  },
];

interface Props {
  dataShop: DataShop;
  history: any;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const ThemeSettingsForm: React.FC<Props> = ({
  dataShop,
  UpdateDataShop,
  SendAlert,
}) => {
  const handleThemeClick = (id: "light" | "dark") => {
    UpdateDataShop(
      dataShop,
      {
        theme_template: id,
        settings_stage:
          dataShop.settings_stage <= 1 ? 2 : dataShop.settings_stage,
      },
      () => {
        SendAlert("41", Translate("shop", "changesSaved"));
        Event("SHOP", `${id.toUpperCase()}_THEME_BUTTON`, "CLICK_EVENT");
      }
    );
  };

  return (
    <div className="ThemeSettingsForm">
      <p>{Translate("shop", "selectTheme")}</p>
      <div className="themes">
        {themes.map((element) => (
          <img
            key={element.id}
            src={`/assets/img/${element.picture}`}
            alt={element.name}
            height={300}
            width={460}
            className={element.id === dataShop?.theme_template ? "active" : ""}
            onClick={() => {
              handleThemeClick(element.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ThemeSettingsForm;
